
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {useEffect, useState} from 'react';
import React from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';
import { object, string } from "yup";
import { cidades } from '../hooks/cidades';
import apiAxios from '../services/api';
import {   isAuthenticated, isAuthenticatedClient, loginClient, logout } from '../services/auth';
import { Modal } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { v4 as uuidv4 } from 'uuid';


function MyVerticallyCenteredModal(props) {

 return (
     <Modal
       {...props}
       size="lg"
       aria-labelledby="contained-modal-title-vcenter"
       centered
     >
       <Modal.Header closeButton>
         <Modal.Title id="contained-modal-title-vcenter">
         {props.titleModal}
         </Modal.Title>
       </Modal.Header>
       <Modal.Body>
         <h4>{props.titleTextBodyModal}</h4>
         <p>
         {props.textBodyModal}
         </p>
       </Modal.Body>
       <Modal.Footer>
         <Button onClick={props.onHide}>Fechar</Button>
       </Modal.Footer>
     </Modal>
   );


}

const schemaLogin = object({
 emailLoginUsuario:string().email('Email inválido').required("Email Obrigatório").min(5,"Minimo de 5 caracteres"),
 senhaLoginUsuario:string().required("Senha Obrigatório").min(6,'Minimo de 6 caracteres').max(6,'Maximo de 6 caracteres')
});

const schemaCadastro = object({
 nome:string().required("Nome Obrigatório").min(3,"Minimo de 3 caracteres").max(25,'Máximo 25 caracteres'),
 rua:string().required("Rua Obrigatório").min(3,"Minimo de 3 caracteres").max(25,'Maximo de 25 caracteres'),
 numero:string().required("Obrigatório"),
 bairro:string().required("Bairro Obrigatório").min(3,"Minimo de 3 caracteres").max(25,'Maximo de 25 caracteres'),
 cep:string().required("Cep Obrigatório").max(9,"Maximo de 9 caracteres").min(9,"Minimo 9 caracteres") ,
 celular:string().required("Celular Obrigatório").transform(value => value.replace(/[^\d]/g, '')).min(11,"Minimo de 11 caracteres"),
 email:string().email('Email inválido').required("Email Obrigatório").min(5,"Minimo de 5 caracteres"),
 password:string().required("Senha Obrigatório").min(6,'Minimo de 6 caracteres').max(6,'Maximo de 6 caracteres'),
 estado:string().required("Informe o Estado"),
 cidade:string().required("Informe a Cidade")
});

 

const SectionFormsCadastroLoginCliente = (props) => {  

  const { executeRecaptcha } = useGoogleReCaptcha();
 const [modalShow, setModalShow] = React.useState(false);
 const [textBodyModal, setTextBodyModal] = React.useState('');
 const [titleTextBodyModal, setTitleTextBodyModal] = React.useState('');
 const [titleModal, setTitleModal] = React.useState('');
 
 const [selectedEstado,setSelectedEstado] = useState('');

 const [isLoggedLoginClient,setIsLoggedLoginCliente] = useState(false);
/*
 useEffect(() => {

  if(isAuthenticatedClient()){
      props.callbackParentClientIsLoged(true);
  }

 },[isLoggedLoginClient]);
*/
 const handleEstadoUpdate = (event) => {
   setSelectedEstado(event.target.value);
 }
  
  const cidaesDoEstadoEscolhido = cidades.filter(x => x.estado === selectedEstado);
  //console.log(cidaesDoEstadoEscolhido);
   const { 
     register, 
     setError : setErrorLogin,
     handleSubmit : onSubmitLogin, 
     watch, 
     formState: { errors } ,
     setFocus : setFocusFormLogin
   } = useForm({resolver:yupResolver(schemaLogin)});

   const {
     register: registerCadastro, 
     handleSubmit: onSubmitCadastroCliente, 
     watch : watchCadastroCliente, 
     formState: {errors: errorsCadastro},
     reset : resetFormCadastroCliente,
     setFocus : setFocusFormCadastro
   } = useForm({
     mode: "onChange",
     resolver: yupResolver(schemaCadastro)
   });

   const handleSubmitLogin = (data:any) => { 

     const urlLoginEstabelecimento = "/usuario/login";
   
     console.log('LOGIN USUARIO: '+data);//Aqui fazer o submit para o backend


          apiAxios.post(urlLoginEstabelecimento, {
          'email' : data.emailLoginUsuario,
          'senha' : data.senhaLoginUsuario
         })
         .then((response) => {
          
           
           if(response.data.auth){ 
            const dadosUsuario = response.data.dataUser ;
              if(!response.data.coordenadasOk){
                //Apresentar mensagem que as coordenadas do estabelecimento estão sendo processadas
                setIsLoggedLoginCliente(false);
                props.callbackParentClientIsLoged(isLoggedLoginClient);
   
                setTextBodyModal('No momento, as coordenadas de seu endereço estão sendo processadas, para uma melhor verificação de ofertas ao seu redor, por favor, entre mais tarde.');
                setTitleTextBodyModal('Coordenadas em processamento');
                setTitleModal('Atenção:');
                setModalShow(true);   
              }
              else{
                //Seguir com Login de Usuario
             //se estabelecimento estiver logado, realiza o Logoff do Estabelecimento
                if(isAuthenticated()){logout()}
                
             loginClient(dadosUsuario.token,dadosUsuario,dadosUsuario.tokenUseInForms);
             setIsLoggedLoginCliente(true);
             props.callbackParentClientIsLoged(isLoggedLoginClient);
             

              }

           }
           else{
             setIsLoggedLoginCliente(false);
             props.callbackParentClientIsLoged(isLoggedLoginClient);

             setTextBodyModal(response.data.message);
             setTitleTextBodyModal('Ops...');
             setTitleModal('Atenção:');
             setModalShow(true);
           }
   
           
         })
         .catch((erro) => {
           
           if(erro.code == 'ERR_NETWORK' && erro.message == 'Network Error'){
             setTextBodyModal('Olá, estamos realizando algumas Melhorias para você, por favor, tente novamente mais tarde ');
             setTitleTextBodyModal('Estamos em Melhorias...');
             setTitleModal('Opsss...');
             setModalShow(true);
           }
           else{
             setTextBodyModal('Olá, estamos realizando algumas Manutenções');
             setTitleTextBodyModal('Estamos em Manutenção...');
             setTitleModal('Opsss...');
             setModalShow(true);
           }
           
          // console.log(erro);
          
         });
   };

   

   props.callbackParentClientIsLoged(isLoggedLoginClient);

   const handleSubmitCadastroCliente = async(data:any) => { 
    console.log('ENVIANDO DADOS DO NOVO USUARIO');
     if(data.cidade == '' || data.cidade == 'Selecione o Estado'){
       alert('Informe a Cidade');
       
     }
     else{ 

      if (!executeRecaptcha) {
        console.log('Execute recaptcha não está disponível ainda');
        return;
      }
  
      const token = await executeRecaptcha('signup');

       const urlNovoEstabelecimento = "/usuario/novo";
     
       apiAxios.post(urlNovoEstabelecimento, {
   
         'email':data.email,
         'rua': data.rua,
         'numero':data.numero.replace('___','').replace('__','').replace('_',''),
         'nome': data.nome,
         'estado':data.estado,
         'cidade':data.cidade,
         'bairro':data.bairro,
         'cep':data.cep,
         'celular':data.celular,
         'password':data.password,
         'tokenRecaptcha': token
       })
       .then((response) => {
         //aqui, tratar a resposta
         //alert('RESPOSTA DO CADASTRO : '+response.data);
         
         if(response.data.succes){
           //resetFormCadastro(data);
           resetFormCadastroCliente({
             numero : '',
             estado : '',
             cidade : '',
             cep: '',
             cnpj : '',
             bairro : '',
             email : '',
             celular : '',
             password: '',
             rua: '',
             nome: ''
           });

           setTextBodyModal(response.data.message);
           setTitleTextBodyModal('Sucesso !');
           setTitleModal('Cadastro :');
           setModalShow(true);

           setFocusFormLogin('emailLoginUsuario');
          
         }
         else{
           //Nao sucesso

           setTextBodyModal(response.data.message);
           setTitleTextBodyModal('Ops, Atenção !');
           setTitleModal('Cadastro :');
           setModalShow(true);

         }
         
       })
       .catch((erro) => {
         //alert('ERRO: '+erro);
         setTitleModal('Seu Cadastro :');
         setTitleTextBodyModal('Oooops...');
         setTextBodyModal('Algo ocorreu, contate o Administrador.');
         setModalShow(true);
       });
       
       
     }

     
};

function verificaCoordenadas(idVerificar,objectEndereco){

  let urlVerificaCoordenadas = "/usuario/verificaCoordenadas";


     apiAxios.post(urlVerificaCoordenadas, {
 
      id:idVerificar,
      endereco: objectEndereco
     })
     .then((response) => {
       //aqui, tratar a resposta
       
     })
     .catch((erro) => {
       //alert('ERRO: '+erro);
      
     });

}

   const validarCNPJ = (cnpj) => {

     cnpj = cnpj.replace(/[^\d]+/g,'');
    
       if(cnpj == ''){ return false;}
        
       if (cnpj.length != 14){
           return false;
       }
    
       // Elimina CNPJs invalidos conhecidos
       if (cnpj == "00000000000000" || 
           cnpj == "11111111111111" || 
           cnpj == "22222222222222" || 
           cnpj == "33333333333333" || 
           cnpj == "44444444444444" || 
           cnpj == "55555555555555" || 
           cnpj == "66666666666666" || 
           cnpj == "77777777777777" || 
           cnpj == "88888888888888" || 
           cnpj == "99999999999999"){
           return false;
           }
            
       // Valida DVs
       var b = [ 6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2 ]
       var c = String(cnpj).replace(/[^\d]/g, '')
       
       if(c.length !== 14){
         return false;
       }
           
   
       if(/0{14}/.test(c)){
         return false;
       }
           
   
       for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
       if(c[12] != (((n %= 11) < 2) ? 0 : 11 - n)){
           return false;
         }
   
       for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
       if(c[13] != (((n %= 11) < 2) ? 0 : 11 - n)){
         return false;
       }
           
   
       return true;
   }


   const TIPO_COMERCIO = ['Tipo de Comércio','Adega', 'Mercado', 'Sacolão', 'Vinícola', 'Bar/Boteco', 'Distr. Bebidas'];
   

   return (
   
 
 
<section className="w-100 px-4 py-5 text-center bg-light">

     <MyVerticallyCenteredModal
     show={modalShow}
     onHide={() => setModalShow(false)}
     textBodyModal={textBodyModal}
     titleTextBodyModal={titleTextBodyModal}
     titleModal={titleModal}
   />   
  
<Container>
<h3 className="mb-5">Descubra Ofertas Exclusivas de Cervejas e Vinhos Perto de Você! é Grátis. Acesse agora, cadastre-se e libere sua localização para visualizar rotas personalizadas de estabelecimentos e aproveite os melhores preços na sua região. GeoGet: a rota certa para suas melhores experiências!</h3>
   
     <Row>
       <Col md={4} className="text-center" >
       <h5 className="mb-5">Faça Login e veja as Promoções e Descontos</h5>
        <Form onSubmit={onSubmitLogin(handleSubmitLogin)}> 
         <Row className="mb-3 centered">
             <Col sm={8} >
              
                 <Form.Control
                 required
           type="email"
           placeholder="Email"
           id="emailLoginUsuario" 
           defaultValue=""
           {...register("emailLoginUsuario")}
         />
         <span style={{'color':'red'}}>{errors?.emailLoginUsuario?.message}</span>
         
             </Col>
            
         </Row>
         <Row className="mb-3 centered">
             <Col sm={8}>
                 
                 <Form.Control
           required
           type="password"
           placeholder="Senha"
           id="senhaLoginUsuario" 
           defaultValue=""
           {...register("senhaLoginUsuario")}
         />
         <span style={{'color':'red'}}>{errors?.senhaLoginUsuario?.message}</span>
             </Col>
             
         </Row>
     
     
         <Row>
         <Button variant="primary" type="submit" className="px-4 float-right mb-3">Entrar</Button>
         </Row>
         
         
           <Row>
          <label style={{ fontSize: '12px' }}><a href="/esqueciSenha" target='_blank'>Esqueci a senha</a></label>
          </Row>
     
     
     </Form>
       </Col>
        
        <Col md={1} className="mb-3">
     <div className="divider">
     <div className="sep">
        
     </div>
   </div>
 </Col>
       
       
       <Col md={6} className="text-center">
       <h5 className="mb-5">Cadastre o Endereço de sua Casa ou Trabalho para ver as Promoções de Vinhos ou Cervejas de estabelecimentos próximos</h5>
          <Form onSubmit={onSubmitCadastroCliente(handleSubmitCadastroCliente)} >
         <Row>
             <Col sm={6} className="mb-3">
                  
                 <Form.Control 
                  
                 type="text" 
                 id="nome" 
                 {...registerCadastro("nome")}
                 placeholder="Nome" 
                 defaultValue=""
                 />
                 <span style={{'color':'red'}}>{errorsCadastro?.nome?.message}</span>
             </Col>
             <Col sm={6} className="mb-3">
                 
                  
             <Form.Control
       required
       type="email"
       id={uuidv4()} 
       {...registerCadastro("email",{required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i})}
       placeholder="Email"
       aria-describedby="Informe Email"
       defaultValue=''
     />
      <span style={{'color':'red'}}>{errorsCadastro?.email?.message}</span>

             </Col>
         </Row>
         <Row>
             <Col sm={6} className="mb-3">
                  
                 <Form.Control
           required
           type="text"
           placeholder="Rua"
           id="rua" 
           {...registerCadastro("rua")}
           defaultValue=""
         />
          <span style={{'color':'red'}}>{errorsCadastro?.rua?.message}</span>
             </Col>
             <Col sm={3} className="mb-3">
                  
                 <Form.Control
           required
           type="text"
           placeholder="Numero"
           id={uuidv4()} 
           {...registerCadastro("numero")}
           defaultValue=""
           as={InputMask}
           mask="999999"
         />
          <span style={{'color':'red'}}>{errorsCadastro?.numero?.message}</span>
             </Col>
            
             
         </Row>
         <Row>
             <Col sm={6} className="mb-3">

             
             <Form.Control
           required
           type="text"
           placeholder="Bairro"
           id={uuidv4()} 
           {...registerCadastro("bairro")}
           defaultValue=""
         />
          <span style={{'color':'red'}}>{errorsCadastro?.bairro?.message}</span>

              
                 
             </Col>
             <Col sm={6} className="mb-3">

             <Form.Select value={selectedEstado} aria-label="Selecione o Estado em que seu comércio está localizado" id={uuidv4()}
                   {...registerCadastro("estado")}
                   onChange={handleEstadoUpdate}
                  >
                   <option value=''>Informe o Estado</option>
                   <option value="AC">Acre</option>
                 <option value="AL">Alagoas</option>
                 <option value="AP">Amapá</option>
                 <option value="AM">Amazonas</option>
                 <option value="BA">Bahia</option>
                 <option value="CE">Ceará</option>
                 <option value="DF">Distrito Federal</option>
                 <option value="ES">Espírito Santo</option>
                 <option value="GO">Goiás</option>
                 <option value="MA">Maranhão</option>
                 <option value="MT">Mato Grosso</option>
                 <option value="MS">Mato Grosso do Sul</option>
                 <option value="MG">Minas Gerais</option>
                 <option value="PA">Pará</option>
                 <option value="PB">Paraíba</option>
                 <option value="PR">Paraná</option>
                 <option value="PE">Pernambuco</option>
                 <option value="PI">Piauí</option>
                 <option value="RJ">Rio de Janeiro</option>
                 <option value="RN">Rio Grande do Norte</option>
                 <option value="RS">Rio Grande do Sul</option>
                 <option value="RO">Rondônia</option>
                 <option value="RR">Roraima</option>
                 <option value="SC">Santa Catarina</option>
                 <option value="SP">São Paulo</option>
                 <option value="SE">Sergipe</option>
                 <option value="TO">Tocantins</option>
                 </Form.Select>
                 <span style={{'color':'red'}}>{errorsCadastro?.estado?.message}</span>

              
                  
                 
             </Col>
         </Row>
         <Row>
         <Col sm={6} className="mb-3">
                 
         <Form.Select aria-label="Selecione a Cidade em que seu comércio está localizado" id={uuidv4()}  {...registerCadastro("cidade",{required:true})}>
                 
                 {cidaesDoEstadoEscolhido[0].cidades.map((cidade) => (
                   <option key={cidade.codigo_ibge} value={  cidade.codigo_ibge === 'Selecione_a_Cidade' ? "" : cidade.nome}>{cidade.nome}</option>
                 ))}
                
               </Form.Select>
               <span style={{'color':'red'}}>{errorsCadastro?.cidade?.message}</span>
             </Col>
         <Col sm={6} className="mb-3">
                 
                 <Form.Control
           required
           type="text"
           placeholder="Cep"
           as={InputMask}
           mask="99999-999"
           id={uuidv4()} 
           {...registerCadastro("cep")}
           
         />
          <span style={{'color':'red'}}>{errorsCadastro?.cep?.message}</span>
             </Col>
         </Row>
         <Row>
             <Col sm={6} className="mb-3">
                 
             <Form.Control
       required
       type="text"
       id={uuidv4()} 
       {...registerCadastro("celular")}
       placeholder="WhatsApp"
       as={InputMask}
       mask="(99)99999-9999"
       aria-describedby="Informe Celular"
       
     />
      <span style={{'color':'red'}}>{errorsCadastro?.celular?.message}</span>
             </Col>
             <Col sm={6} className="mb-3">
                  
             <Form.Control
       required
       type="password"
       id="password" 
       {...registerCadastro("password")}
       placeholder="Senha"
       aria-describedby="Informe Senha"
       defaultValue=''
     />
      <span style={{'color':'red'}}>{errorsCadastro?.password?.message}</span>
             </Col>
         </Row>
        
         
         <Row>
         <Button variant="primary" type="submit" className="px-4 float-right">Cadastrar</Button>
         </Row>

         <Row>
         <label style={{ fontSize: '12px' }}>Ao clicar em Cadastrar você concorda com <a href="/politicaPrivacidade" target='_blank'>Política de Privacidade</a> e <a href="/termosUso" target='_blank'>Termos de Uso</a></label>
         </Row>
         
         
     </Form>
       </Col>
     </Row>
   
</Container>
</section>
   );
}

export default SectionFormsCadastroLoginCliente;
















