import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import BeneficiosGeoGet from '../assets/images/beneficioogeoget.webp';
import Figure from 'react-bootstrap/Figure';

 


const SectionCardsCervejaVinho = () => {

  const sectionTitleStyle = {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    color: '#007bff',
    marginBottom: '1.5rem',
  };

  const benefitsListStyle = {
    listStyleType: 'none',
    padding: 0,
  };

  const benefitsListItemStyle = {
    fontSize: '1.25rem',
    marginBottom: '0.75rem',
    paddingLeft: '1.5rem',
    position: 'relative',
  };

  const benefitsListItemBeforeStyle = {
    content: "'✓'",
    color: '#007bff',
    position: 'absolute',
    left: 0,
    top: 0,
    fontSize: '1.5rem',
  };

  const markerStyle = {
    width: '1rem',
    height: '1rem',
    borderRadius: '50%',
    backgroundColor: '#007bff',
    display: 'inline-block',
    marginRight: '0.5rem',
  };


    return (
      <section class="py-5">
      <Container>
      <Row className="align-items-center">
      <Col md={6}>
      <Figure>
            <Figure.Image
        width={600}
        height={400}
        alt="Beneficios GeoGet"
        src={BeneficiosGeoGet}
        className="img-fluid"
                
      />
       </Figure>
          </Col>
          <Col md={6}>
          <h2 style={sectionTitleStyle}>O que a GeoGet faz ?</h2>
            <p className="mb-3">A Geoget ajuda você a encontrar com facilidade as melhores promoções de cervejas e vinhos ao seu redor.</p>
            <ul style={benefitsListStyle}>
            {['Atualizações em tempo real sobre promoções', 
            'Alertas personalizáveis ​​para suas bebidas favoritas', 
            'Recomendações baseadas em geolocalização', 
            'Ofertas e descontos exclusivos'].map((benefit, index) => (
                <li key={index} style={benefitsListItemStyle}>
                  <span style={markerStyle}></span>
                  {benefit}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
    );
}

export default SectionCardsCervejaVinho;



