import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {  Nav  } from "react-bootstrap";


const FooterComponent = () => {
    return (
        <footer className="footer bg-white py-4">
  <Container>
    <Row className="align-items-center">
      <Col lg={6} className="text-center text-lg-start mb-3 mb-lg-0">
        <ul className="list-inline">
          <li className="list-inline-item"><Nav.Link href="/sobre">Sobre a GeoGet</Nav.Link></li>
          <li className="list-inline-item">⋅</li>
          <li className="list-inline-item"><Nav.Link href="/contato">Contato</Nav.Link></li>
          <li className="list-inline-item">⋅</li>
          <li className="list-inline-item"><Nav.Link href="/termosUso">Termos de Uso</Nav.Link></li>
          <li className="list-inline-item">⋅</li>
          <li className="list-inline-item"><Nav.Link href="/politicaPrivacidade">Política de Privacidade</Nav.Link></li>
        </ul>
        <p className="text-muted small">&copy; GeoGet 2024. Todos os direitos reservados.</p>
      </Col>
      <Col lg={6} className="text-center text-lg-end">
        <ul className="list-inline mb-0">
          <li className="list-inline-item me-3">
            <a href="#!" aria-label="Facebook"><i className="bi-facebook fs-3"></i></a>
          </li>
          <li className="list-inline-item me-3">
            <a href="#!" aria-label="Twitter"><i className="bi-twitter fs-3"></i></a>
          </li>
          <li className="list-inline-item">
            <a href="#!" aria-label="Instagram"><i className="bi-instagram fs-3"></i></a>
          </li>
        </ul>
      </Col>
    </Row>
  </Container>
</footer>

    );
}

export default FooterComponent;





 