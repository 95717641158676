import React from "react";
import { useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import { socketPing } from "../context/socket";
import { useState } from "react";
import { mountCards, mountTimeLine } from "../hooks/useMountScreen";
import SectionLibereSuaLocalizacao from "./sectionLibereSuaLocalizacao";
import MapLeafletForUsers from "./mapLeafletForUsers";
import { getTokenIsAuthenticatedClient, isAuthenticatedClient } from "../services/auth";
import { v4 as uuidv4 } from 'uuid';
import { verifyNotification } from "../hooks/useNotifications";
import Col from 'react-bootstrap/Col';
import getCoordinates from "../hooks/getCoordinates";
import SectionRotas from "./SectionRotas";
//import addNotification from 'react-push-notification';



const SectionTimeLine = (props) => { 

  
//  const socket = useContext(SocketContext);
  const [cardsForAreaCards , setCardsForAreaCards] = useState(['Ao se locomover, iremos atualizando os lugares mais proximos, com promoções']);


const [posicaoAtual, setPosicaoAtual] = useState({'latitude':null, 'longitude':null});

const [estabelecimentos, setEstabelecimentos] = useState([]);
const [qtdeVinhos, setQtdeVinhos] = useState(0);
const [qtdeCervejas, setQtdeCervejas] = useState(0);
const [qtdeCarros, setQtdeCarross] = useState(0);
const [urlRotaCerveja, setUrlRotaCerveja] = useState(null);
const [urlRotaVinho, setUrlRotaVinho] = useState(null);

const [location, setLocation] = useState({
  status: null,
  permissionState: null,
  latitude: null,
  longitude: null,
  message: '',
});

const [userRespondedNotification, setUserRespondedNotification] = useState(false);

  

  useEffect(() => {

    socketPing.connect();

    // Função para atualizar a localização
    const updateLocation = async () => {
      const locationData = await getCoordinates();
      setLocation(locationData);
    };

     // Chama a função de atualização inicialmente
     //updateLocation();
   
   
    
   
   const interval = setInterval(updateLocation, 5000);
    
   //funcao para detectar o fechamento da aba ou janela
   const handleUnload = async () =>{ 
    
    
    socketPing.disconnect(true);
    localStorage.removeItem('socketIdPing');
    clearInterval(interval);
    
   }

   window.addEventListener('beforeunload',handleUnload);

    return () =>{ // return é a função de cleanup 
     
      console.log('DESMONTANDOCLEAN NA TIMELINEEEE');
      
      socketPing.disconnect(true);
      localStorage.removeItem('socketIdPing');
      window.removeEventListener('beforeunload',handleUnload);
      clearInterval(interval);
      

    };
    
   
  },[]);

  useEffect(() => {

    if(location.permissionState === 'granted' && location.status === 'success'){ 
      console.log('vai chamar o handleCurrentLocation');
      handleCurrentLocation();
    }

  },[location]);




  

  const handleCurrentLocation = async () => { 
    
  
  if (
    JSON.stringify({'latitude':posicaoAtual.latitude,'longitude':posicaoAtual.longitude }) !== 
    JSON.stringify({'latitude':location.latitude, 'longitude':location.longitude})) { 
      setPosicaoAtual({'latitude':location.latitude, 'longitude':location.longitude}) ;
      //setCurrentLocation(posicaoAtual);
      //Executar aqui a verificação com o socket
     
    
        var produtosPreferencia = ['Vinhos','Cervejas'];
        var categoriasPreferencia = ['Cervejas','Vinhos'];

        const data = {

            'latitude' : location.latitude, 
            'longitude' : location.longitude,
            'latitudeAtual' : null  ,
            'longitudeAtual' : null ,
            'distanciaMetros' : '3000',
            'produtos' : produtosPreferencia,
            'categorias' : categoriasPreferencia,
            'dadosCliente' : ( isAuthenticatedClient() ?  getTokenIsAuthenticatedClient() : null),
            'clienteLogado' : false

        };
        console.log('ENVIANDO DATA PARA PING => '+JSON.stringify(data));
          //console.log('Enviando para servidor: latitude : '+ location.coords.latitude + ' longitude : '+location.coords.longitude);
          //AQUI QUE A MAGICA ACONTECE
         // console.log('ENVIANDO AO PING SOCKET SECTIONTIMELINE => '+JSON.stringify(data));  
          socketPing.emit("/ping", data);

    }

 
 
  };

  async function enableNotifications(){
    await verifyNotification().then(() =>{
      setUserRespondedNotification(true);
    });
    

  }

  function notEnableNotifications(){
    setUserRespondedNotification(true);
  }


  socketPing.on('ping', data =>{ 
    
    if(data.publicacaoProdutos){ 

      console.log('OPAAAA RECEBEMOS PUBLICACAO DE PRODUTOS => '+ JSON.stringify(data.produtosPublicados));

    }else{

     // console.log('PING RECEBIDO => '+ JSON.stringify(data));
      var arrayProdutos = [];
      var arrayEstabelecimentosEclientes = [];
var verificaTemEstabelecimentos = (
          
  !data.erro && data.estabelecimentosPorCategoriaProdutosPing.length > 0   ?
  true
  :
  false
  );

  var verificaTemClientes = (
          
    !data.erro && data.usuariosPorCategoriaProdutosPing.length > 0   ?
    true
    :
    false
    );

  if(verificaTemEstabelecimentos){ 

    data.estabelecimentosPorCategoriaProdutosPing.forEach(async (estab) => { 

      if(estab.produtos_venda.length > 0 ){
        estab.produtos_venda.forEach(async (produto) => {
          arrayProdutos.push(produto);
        });
      }

      arrayEstabelecimentosEclientes.push(estab);
    });
    
  }

  if(verificaTemClientes){ 

    data.usuariosPorCategoriaProdutosPing.forEach(async (cliente) => { 
      
      if(cliente.produtos_venda.length > 0 ){
        cliente.produtos_venda.forEach(async (produto) => {
          arrayProdutos.push(produto);
        });
      }
      arrayEstabelecimentosEclientes.push(cliente);
    });

  }


    

  if(arrayEstabelecimentosEclientes.length > 0 ){
   // console.log('AQUI ESTA OS ESTABELECIMENTOS => '+JSON.stringify(arrayEstabelecimentosEclientes));
    setEstabelecimentos(arrayEstabelecimentosEclientes);
    
    //PARA CONTAR QTDE DE CERVEJAS E DE VINHOS
    let qtdeCervejas = 0 ;
    let qtdeVinhos = 0 ;
    let qtdeCarros = 0 ;
   
     if(arrayProdutos.length > 0){
     
      arrayProdutos.forEach(async (produto) => {

        if(produto.categoria === 'Vinhos'){
          qtdeVinhos++;
        }
        else if(produto.categoria === 'Cervejas'){
          qtdeCervejas++;
        }else if(produto.categoria === 'Carros'){
          qtdeCarros++;
        }

      });
     
     }
  
    setQtdeCervejas(qtdeCervejas);
    setQtdeVinhos(qtdeVinhos);
    setQtdeCarross(qtdeCarros);

    
    const cardsMontados = mountCards(data);
        
    //Aqui atualizar as areas da tela
    setCardsForAreaCards(cardsMontados);
  
    //const timeLineMontada = mountTimeLine(data,timelineForAreaTimeLine);
    console.log('PERMISSAO DE NOTIFICACOES => '+Notification.permission);
    

    arrayEstabelecimentosEclientes.forEach(element => { 
    
      //Itera nos produtos a venda do Estabelecimento
      element.produtos_venda.forEach(prodVenda => {
      
       let urlGooGleMaps = new URL('https://www.google.com/maps/dir/');

           urlGooGleMaps.searchParams.append('q', element.rua+','+element.numero+' - '+element.bairro+','+' '+element.cidade+' - '+element.estado+', '+element.cep);
           //Av. Santo Albano, 848 - Vila Vera, São Paulo - SP, 04296-000
      
         const tituloNotificacao = prodVenda.categoria+' '+prodVenda.nome_produto+' '+prodVenda.ml+'ml - R$'+prodVenda.preco;
         const subTituloNotificacao = 'Distância: '+element.distanciaEmKilometros.toFixed(1)+' KM';
         const mensagemNotificacao = subTituloNotificacao+', '+tituloNotificacao+', CLIQUE AQUI E VÁ BUSCAR';
        /* addNotification({
           title: tituloNotificacao,
           subtitle: subTituloNotificacao,
           message: mensagemNotificacao,
           theme: 'red',
           native: true, // when using native, your OS will handle theming.
           duration: 5000, //optional, default: 5000,
           onClick: (e: Event | Notification) => { window.open(urlGooGleMaps, '_blank', 'noopener');} //optional, onClick callback.
          
          
       });*/

      });

   });


  }


    }

        });


  if(
    (location.permissionState === 'granted' && location.status === 'success') 
    &&
    (posicaoAtual.latitude != null && posicaoAtual.longitude != null) 
    ){

    return (
      <>  
   
    
  <section key={uuidv4()}  className="text-center padding-top-1rem padding-bottom-3rem"> 
  <Container key={uuidv4()} >
  <h3 className="mb-3 text-center"  >Explore os melhores rótulos de cervejas e vinhos ao seu redor, em apenas um clique!</h3>
  <h4 className="mb-4 text-center"  >{(estabelecimentos !== null && estabelecimentos.length > 0 ? estabelecimentos.length+ ' Estabelecimentos até 3Km de você !' : '')}</h4>
  <h4 className="mb-4 text-center"  >{qtdeCervejas} Cervejas, {qtdeVinhos} Vinhos</h4>
     
        
   
      {cardsForAreaCards}
                      
            </Container>
  </section>

<section key='123445' className="text-center" style={{"padding-top": "2rem", "padding-bottom": "3rem"}}> 
<Container>

<MapLeafletForUsers    
            arrayMarkers={estabelecimentos}
            centroMapaPosicaoUser={[posicaoAtual.latitude,posicaoAtual.longitude]}
            //posicaoUser={[posicaoAtual.latitude,posicaoAtual.longitude]}
            dataUserCenter={{nome:'Você'}}
            mapParaEstabelecimento={false}
            raioCirculo = {500}
            />



          </Container>
          </section>
          

{
   (estabelecimentos != null && estabelecimentos.length > 0 ? <SectionRotas estabelecimentos={estabelecimentos} origin={{ lat: posicaoAtual.latitude, lng: posicaoAtual.longitude }} /> : '')
   }
   
</>

    );



  }
  else{
    return (<SectionLibereSuaLocalizacao permissionState = {location.permissionState} statusGetLocaton = {location.status}/>);
  }

    
}

export default SectionTimeLine;


