import {
    Container,
    Row,
    Col,
    Figure
} from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import {Link} from 'react-router-dom';
import ImagemRotaCerveja from '../assets/images/rotacervejaok.webp';
import ImagemRotaVinho from '../assets/images/rotavinhook.webp';
import { useState,useEffect } from "react";






const SectionRotas = (props) => { 



    const [estabelecimentos,setEstabelecimentos] = useState([]);
    const [urlRotaCerveja, setUrlRotaCerveja] = useState(null);
    const [urlRotaVinho, setUrlRotaVinho] = useState(null);
    
    const [estabelecimentosCerveja, setEstabelecimentosCerveja] = useState([{}]);
    const [estabelecimentosVinho, setEstabelecimentosVinho] = useState([{}]);
    const [origin, setOrigin] = useState({ lat: null, lng: null });
    

    useEffect(() => {

      const arrayEstabelecimentosCerveja = [];
      const arrayEstabelecimentosVinho = [];
      
      estabelecimentos.forEach(estabelecimento => {
        // Verifica se o estabelecimento tem produtos da categoria Cervejas
        const hasCervejas = estabelecimento.produtos_venda.some(produto => produto.categoria === 'Cervejas');
        if (hasCervejas) {
          arrayEstabelecimentosCerveja.push(estabelecimento);
        }
      
        // Verifica se o estabelecimento tem produtos da categoria Vinhos
        const hasVinhos = estabelecimento.produtos_venda.some(produto => produto.categoria === 'Vinhos');
        if (hasVinhos) {
          arrayEstabelecimentosVinho.push(estabelecimento);
        }
      });

      if(arrayEstabelecimentosCerveja.length > 0){
        setEstabelecimentosCerveja(arrayEstabelecimentosCerveja);
      }

      if(arrayEstabelecimentosVinho.length > 0){
        setEstabelecimentosVinho(arrayEstabelecimentosVinho);
      }

      if(estabelecimentosCerveja.length > 0){
        //Criar rotas das Cervejas
        const ultimoEstabelecimentoCerveja = estabelecimentosCerveja[estabelecimentosCerveja.length - 1];
        const destinationCerveja = { lat: ultimoEstabelecimentoCerveja.latitude, lng: ultimoEstabelecimentoCerveja.longitude }; 
        const waypointsCerveja = [];
        estabelecimentosCerveja.forEach(estabelecimentoCerveja => {
  
          if(estabelecimentoCerveja._id !== ultimoEstabelecimentoCerveja._id ){
  
            const waypointCerva = {lat: estabelecimentoCerveja.latitude, lng: estabelecimentoCerveja.longitude};
          
            waypointsCerveja.push(waypointCerva);
          }
          
  
        });
        
        const rotaCriadaCerveja = criarRotas(origin, waypointsCerveja, destinationCerveja);
        setUrlRotaCerveja(rotaCriadaCerveja);
  
      }

      if(estabelecimentosVinho.length > 0){
        //Criar rotas dos Vinhos
  
  
  
  
        const ultimoEstabelecimentoVinho = estabelecimentosVinho[estabelecimentosVinho.length - 1];
        const destinationVinho = { lat: ultimoEstabelecimentoVinho.latitude, lng: ultimoEstabelecimentoVinho.longitude }; 
        const waypointsVinho = [];
        estabelecimentosVinho.forEach(estabelecimentoVinho => {
  
          if(estabelecimentoVinho._id !== ultimoEstabelecimentoVinho._id ){
  
            const waypointVinho = {lat: estabelecimentoVinho.latitude, lng: estabelecimentoVinho.longitude};
          
            waypointsVinho.push(waypointVinho);
          }
          
  
        });
        
        const rotaCriadaVinho = criarRotas(origin, waypointsVinho, destinationVinho);
        setUrlRotaVinho(rotaCriadaVinho);
  
      }
  
    },[estabelecimentos]);

    useEffect(() => {
      console.log('ORIGIN NO ROTAS=> '+JSON.stringify(props.origin));
      setEstabelecimentos(props.estabelecimentos);
      setOrigin(props.origin);

    },[]);

    

    //https://developers.google.com/maps/documentation/urls/get-started?hl=pt-br
    const criarRotas = (origin, waypoints, destination) =>{
        const baseUrl = 'https://www.google.com/maps/dir/?api=1';
        const originParam = `&origin=${origin.lat},${origin.lng}`;
        const destinationParam = `&destination=${destination.lat},${destination.lng}`;
        const waypointsParam = waypoints.length 
          ? `&waypoints=${waypoints.map(wp => `${wp.lat},${wp.lng}`).join('|')}`
          : '';
        const travelModeParam = '&travelmode=driving';
    
        return `${baseUrl}${originParam}${destinationParam}${waypointsParam}${travelModeParam}`;
      }

    

      const shouldCenter = (urlRotaCerveja && !urlRotaVinho) || (!urlRotaCerveja && urlRotaVinho);


      return (
        <div>
          {
            estabelecimentos !== null && 
            estabelecimentos.length > 0 && 
            (urlRotaCerveja !== null || urlRotaVinho !== null) ? 
            
            (
              <section key={uuidv4()}>
  <Container className="py-5">
    <h2 className="text-center mb-5" style={{ fontSize: '1.75rem', fontWeight: 'bold', color: '#007bff' }}>
      Está em dúvida sobre onde tomar uma boa cerveja ou vinho? Deixe que a GeoGet te mostre o caminho!
      Escolha a Rota da Cerveja ou a Rota do Vinho, conforme os estabelecimentos ao seu redor.
    </h2>

    <Row className="justify-content-center">
      { 
        (estabelecimentosCerveja.length > 1 || (estabelecimentosCerveja.length === 1 && Object.keys(estabelecimentosCerveja[0]).length > 0)) && urlRotaCerveja !== null ? (
          <Col md={urlRotaVinho === null ? 12 : 6} className="mb-4 text-center">
            <Link to={urlRotaCerveja} target='_blank'>
              <Figure>
                <Figure.Image
                  width="100%"
                  height="auto"
                  alt="Rota da Cerveja"
                  src={ImagemRotaCerveja}
                  className="img-fluid rounded shadow-sm"
                />
                <Figure.Caption className="mt-3" style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#007bff' }}>
                  Rota da Cerveja
                </Figure.Caption>
              </Figure>
            </Link>
          </Col>
        ) : null
      }

      { 
        (estabelecimentosVinho.length > 1 || (estabelecimentosVinho.length === 1 && Object.keys(estabelecimentosVinho[0]).length > 0)) && urlRotaVinho !== null ? (
          <Col md={urlRotaCerveja === null ? 12 : 6} className="mb-4 text-center">
            <Link to={urlRotaVinho} target='_blank'>
              <Figure>
                <Figure.Image
                  width="100%"
                  height="auto"
                  alt="Rota do Vinho"
                  src={ImagemRotaVinho}
                  className="img-fluid rounded shadow-sm"
                />
                <Figure.Caption className="mt-3" style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#007bff' }}>
                  Rota do Vinho
                </Figure.Caption>
              </Figure>
            </Link>
          </Col>
        ) : null
      }
    </Row>
  </Container>
</section>

            )
            : null
          }
        </div>
      );
      

}

export default SectionRotas;