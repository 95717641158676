import Row  from "react-bootstrap/Row";
import Col  from "react-bootstrap/Col";
import Card  from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { ListGroup } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';
import Badge from 'react-bootstrap/Badge';
import { converterDecimal128ParaPrecoBrasil, criarUrlGoogleMapsDirect } from "../util/util";
import apiAxios from "../services/api";
import { getTokenIsAuthenticatedClient, isAuthenticatedClient } from "../services/auth";
import { FaHandshake, FaCar, FaBeer, FaWineGlass,FaWhatsapp } from 'react-icons/fa'; // Importando ícones do react-icons



export const defaultAreaCards = () => {

const defaultReturn = ['Ao se locomover, iremos atualizando os lugares mais proximos, com promoções'];

    return defaultReturn;

}

const getItemsTime = (arrayProdutos,arrayInicial) => {
   
    var contador = 1;
        arrayProdutos.map((produto) => { 
        
        var idProdutoNovo = produto.idProduto;
        var distanciaProdutoNovo = produto.distanciaProduto;

        var adicionar = true;

        arrayInicial.map((produtoInicial) => {
           
            var idProdutoLista = produtoInicial.props.children.props.id;
            var distanciaProdutoLista = produtoInicial.props.children.props.distancia;

            if(idProdutoLista == idProdutoNovo && parseFloat(distanciaProdutoLista).toFixed(1) == parseFloat(distanciaProdutoNovo).toFixed(1)){
                adicionar = false;
            }
        })
        //CONTROLE SE NAO VAI ADICIONAR PRODUTOS REPETIDOS NA LISTA
        if(adicionar){
            arrayInicial.unshift(getItemTimeLine(produto,contador));  
            contador++;
        }
            
            
       });

    return arrayInicial;
    /*
    let items = [];

    arrayProdutos.map((produto) => {
        
        items.push(getItemTimeLine(produto)); 
        
   });
   
   return items;
   */
}

 


export const mountTimeLine = (dataObject,arrayListaUl) => { 

    const arrayProdutos = getArrayProdutos(dataObject);
            
        if(arrayListaUl.length == 1 && arrayListaUl[0] == 'Ao se locomover, iremos atualizando os lugares mais proximos, com promoções'){
            arrayListaUl = [];
        }

            return getItemsTime(arrayProdutos,arrayListaUl);
    
}

function getItemTimeLine(produto,contador){
  
    var variant = (produto.categoriaProduto == 'Cervejas' ? 'warning' : 'success');

    var idProd = produto.idProduto.toString();
     var dirEsquerda = (contador % 2 == 0   ?   ''   :    'timeline-inverted'   );
     var iconeVinhoCerveja = (produto.categoriaProduto == 'Vinhos'  ?  'fa-solid fa-wine-glass'   :   'fa-solid fa-beer-mug-empty');
    var badgeTimeLineVinhoCerveja = (produto.categoriaProduto == 'Vinhos'  ?  'timeline-badge-vinho'   :   'timeline-badge-cerveja');
     return(
    <>
    <li className={dirEsquerda} distancia={produto.distanciaProduto} id={idProd} key={idProd} >
          <div className={badgeTimeLineVinhoCerveja}><i className={iconeVinhoCerveja}></i></div>
          <div className="timeline-panel">
            <div className="timeline-heading">
              <h4 className="timeline-title">{produto.nomeProduto}</h4>
              <p><small className="text-muted"><i className="glyphicon glyphicon-time"></i> {produto.categoriaProduto}</small></p>
            </div>
            <div className="timeline-body">
              <p>De: R$ {produto.precoDe} por R$ {produto.precoProduto}</p>
              <p>Distância de você: {produto.distanciaProduto} Km ou {produto.distanciaEmMetros} Metros </p>
            <p>Estabelecimento: {produto.nomeEstabelecimento}</p>
            <p className="text-muted">
      {produto.descricaoProduto}
    </p>
    <p className="text-muted">
     <a href={produto.urlGooGleMaps} target="_blank">Ir Buscar</a> 
    </p>
            </div>
          </div>
        </li>
        </>
 
  )
  ;
 
}
 
export const getCardsForAreaClientLogged = (estabelecimentos,tipoBuscaCategorias) => {
   // console.log('CHEGOU AQUI TIPOBUSCACATEGORIAS => '+tipoBuscaCategorias);
    
    const arrayReturn = [];
    const qtdeEstabelecimentos = estabelecimentos.length;
    //console.log('MONTANDO  => '+qtdeEstabelecimentos);


  var arrayComCardsMontados =  estabelecimentos.map((estab) =>
     
            <Col xl={3} md={3} className="mb-4" > 
                {getCardClientLogged(estab,tipoBuscaCategorias)}
                </Col>
             
    );

    var rr =( <div><Row className="mt-4" >
           
    {arrayComCardsMontados}
   
</Row></div>);
arrayReturn.push(rr);

        return arrayReturn;
}



export const mountCards = (dataObject,categoriasPrefrencia = null) =>{

    const arrayProdutos = 
    (
        categoriasPrefrencia !== null ?
        getArrayProdutos(dataObject,categoriasPrefrencia)
        :
        getArrayProdutos(dataObject)
    );

    arrayProdutos.sort((a,b) => parseFloat(a.distanciaProduto) - parseFloat(b.distanciaProduto));

    const arrayReturn = [];

     // conversion factor
  const factor = 0.621371;
    
  var arrayComProdutosMontadosCards = arrayProdutos.map((prod) =>
     
            <Col xl={3} md={3} className="mb-4" key={prod.idProduto}>
                {
                 prod.categoriaProduto === 'Carros' ?
                 getCardCarros(prod)
                 :
                 getCard(prod)
                 }
                </Col>
             
    );

    var rr =( <div><Row className="mt-4">
           
    {arrayComProdutosMontadosCards}
   
</Row></div>);
arrayReturn.push(rr);

    return arrayReturn;
}


function getArrayProdutos(dataObject,categoriasPrefrencia = null){

    const arrayProdutos = [];

    //Itera nos Estabelecimentos = element
    dataObject.estabelecimentosPorCategoriaProdutosPing.forEach(element => {
        
        let nomeEstabelecimento = element.nome_estabelecimento;
        const idEstab = element._id;
        var telefone = element.telefone.replaceAll('(','').replaceAll(')','').replaceAll('-','').replaceAll(' ','');
        const planoAtivoEstabelecimento = (element.isPlanoAtivo ? true : false);
        const isEstabelecimentoParceiroMotorista = (element.isParticipaRedeMotoristasAppTaxista ? true : false);

        var distanciaEmMetros = element.distanciaEmKilometros * 1000 ;
        distanciaEmMetros = parseFloat(distanciaEmMetros).toFixed(0);

        //Itera nos produtos a venda do Estabelecimento
        element.produtos_venda.forEach(prodVenda => {
            
            if(categoriasPrefrencia === null || (categoriasPrefrencia !== null && categoriasPrefrencia.includes(prodVenda.categoria)) ){
            
                let urlGooGleMaps = new URL('https://www.google.com/maps/search/?api=1&');
  
                urlGooGleMaps.searchParams.append('query', element.rua+','+element.numero+' - '+element.bairro+','+' '+element.cidade+' - '+element.estado+', '+element.cep);
                //Av. Santo Albano, 848 - Vila Vera, São Paulo - SP, 04296-000
                
            const linkWhatsApp = 
    'https://api.whatsapp.com/send?phone=55'+telefone+'&text=Ol%C3%A1,%20estou%20interessado%20no%20'+prodVenda.nome_produto+'%20anunciado por R$'+prodVenda.preco+'%20publicados%20na%20GeoGet...'
                
               
                const objectDadosProduto = {
                    nomeProduto : prodVenda.nome_produto,
                    precoProduto : converterDecimal128ParaPrecoBrasil(prodVenda.preco),
                    distanciaProduto : element.distanciaEmKilometros.toFixed(1),
                    distanciaEmMetros : distanciaEmMetros,
                    categoriaProduto : prodVenda.categoria,
                    urlGooGleMaps :urlGooGleMaps,
                    precoDe: converterDecimal128ParaPrecoBrasil(prodVenda.precoDe),
                    subCategoriaProduto : prodVenda.sub_categoria,
                    descricaoProduto: prodVenda.descricao,
                    marcaProduto: prodVenda.marca,
                    codPromocao: prodVenda.codigo_promocao,
                    idProduto : prodVenda._id,
                    idEstabelecimento: idEstab,
                    nomeEstabelecimento : nomeEstabelecimento,
                    linkWhatsApp : linkWhatsApp,
                    marca : prodVenda.marca,
                    ml : prodVenda.ml,
                    kilometros_rodados : prodVenda.kilometros_rodados,
                    ano_modelo: (prodVenda.ano_modelo !== null ?  prodVenda.ano_modelo : ''  ),
                    ano_fabricacao: (prodVenda.ano_fabricacao !== null ?  prodVenda.ano_fabricacao : ''  ),
                    tipoUsuario: 'estabelecimento',
                    isPlanoAtivoEstabelecimento: planoAtivoEstabelecimento,
                    isEstabelecimentoParceiroMotoristas: isEstabelecimentoParceiroMotorista

                };
        
                arrayProdutos.push(objectDadosProduto);

                
                
            }

            else{
                return;
            }

    });


    });

    //Abaixo fazer o foreach para clientes

    dataObject.usuariosPorCategoriaProdutosPing?.forEach(element => {
        
        let nomeEstabelecimento = 'Produto Particular';
        var telefone = element.celular.replaceAll('(','').replaceAll(')','').replaceAll('-','').replaceAll(' ','');

        var distanciaEmMetros = element.distanciaEmKilometros * 1000 ;
        distanciaEmMetros = parseFloat(distanciaEmMetros).toFixed(0);


        //Itera nos produtos a venda do Estabelecimento
        element.produtos_venda.forEach(prodVenda => {
            
            if(arrayProdutos.length <= 200){
            
                let urlGooGleMaps = new URL('https://www.google.com/maps/dir/');
  
                urlGooGleMaps.searchParams.append('q', element.rua+','+element.numero+' - '+element.bairro+','+' '+element.cidade+' - '+element.estado+', '+element.cep);
                //Av. Santo Albano, 848 - Vila Vera, São Paulo - SP, 04296-000
                
            const linkWhatsApp = 
    'https://api.whatsapp.com/send?phone=55'+telefone+'&text=Ol%C3%A1,%20estou%20interessado%20no%20'+prodVenda.nome_produto+'%20anunciado por R$'+prodVenda.preco+'%20publicados%20na%20GeoGet...'
                
               
                const objectDadosProduto = {
                    nomeProduto : prodVenda.nome_produto,
                    precoProduto : prodVenda.preco,
                    distanciaProduto : element.distanciaEmKilometros.toFixed(1),
                    distanciaEmMetros : distanciaEmMetros,
                    categoriaProduto : prodVenda.categoria,
                    urlGooGleMaps :urlGooGleMaps,
                    precoDe: 'R$ 0,00',
                    subCategoriaProduto : '--',
                    descricaoProduto: prodVenda.descricao,
                    marcaProduto: prodVenda.marca,
                    idProduto : prodVenda._id,
                    nomeEstabelecimento : 'Produto Particular',
                    linkWhatsApp :  linkWhatsApp,
                    marca : prodVenda.marca,
                    kilometros_rodados : (prodVenda.kilometros_rodados != null ? prodVenda.kilometros_rodados  :  '000' ),
                    ano_modelo: (prodVenda.ano_modelo !== null ?  prodVenda.ano_modelo : ''  ),
                    ano_fabricacao: (prodVenda.ano_fabricacao !== null ?  prodVenda.ano_fabricacao : ''  ),
                    tipoUsuario: 'particular'

                };
        
                arrayProdutos.push(objectDadosProduto);

                
                
            }
            else{
                return;
            }

    });


    });

    return arrayProdutos;

}

async function getCardClientLogged(estabelecimento,tipoBuscaCategorias){
    
    var isEstabelecimento = (estabelecimento.cnpj != null && estabelecimento.cnpj != 'undefined' ? true  : false);
    const qtdeProdutosVenda = estabelecimento.produtos_venda.length;

    let listItems = [];

    if(qtdeProdutosVenda > 0){

        listItems = estabelecimento.produtos_venda.map((produto) =>

    
        tipoBuscaCategorias == null ||
        tipoBuscaCategorias.includes(produto.categoria)

        ?

    <ListGroup.Item 
    key={uuidv4()} 
    className={produto.categoria == 'Vinhos' ?  'list-group-item-vinho'    :  'list-group-item-warning'}
    >{produto.nome_produto + ' R$' + produto.preco}</ListGroup.Item>

    :
    ''
  
  );

    }
    else{

        listItems.push( 

            <ListGroup.Item key={uuidv4()}>{'Cadastrando Produtos...'}</ListGroup.Item>
            
          );

    }

    var telefone = '';
    if(isEstabelecimento){
        telefone = estabelecimento.telefone.replaceAll('(','').replaceAll(')','').replaceAll('-','').replaceAll(' ','');
    }
    else{
        telefone = estabelecimento.celular.replaceAll('(','').replaceAll(')','').replaceAll('-','').replaceAll(' ','');
    }

      

    const linkWhatsApp = 
    'https://api.whatsapp.com/send?phone=55'+telefone+'&text=Ol%C3%A1,%20estou%20interessado%20em%20seus%20produtos%20publicados%20na%20GeoGet...';

  listItems.push( 

    <ListGroup.Item key={uuidv4()}>{
        <a href={linkWhatsApp} target="_blank">Chamar pelo WhatsApp</a>}</ListGroup.Item>
    
  );

  let urlGooGleMaps = await criarUrlGoogleMapsDirect(
    estabelecimento.rua,
    estabelecimento.numero,
    estabelecimento.bairro,
    estabelecimento.cidade,
    estabelecimento.estado,
    estabelecimento.cep
  );
  
  listItems.push( 

    <ListGroup.Item key={uuidv4()}>{
        <a href={urlGooGleMaps} target="_blank">Ir Buscar</a>}</ListGroup.Item>
    
  );

return ( 

<Card style={{"width": "16rem"}}  >
  <Card.Header >
  <b>{estabelecimento.nome_estabelecimento}</b>
  </Card.Header>
  <Card.Header >
  <b>{'Tipo: '+estabelecimento.tipo_estabelecimento}</b>
  </Card.Header>
  <Card.Header >
    <b>{'Distância: '+estabelecimento.distanciaEmKilometros.toFixed(3) * 1000+' Metros'}</b>
  </Card.Header>
  <ListGroup className="list-group-flush">
    {listItems}
  </ListGroup>
</Card>

       
            );

}



function getCard(produto) {
  var classNameCard =
    produto.categoriaProduto === 'Cervejas'
      ? 'border-left-warning shadow h-100 py-2'
      : 'border-left-vinhos shadow h-100 py-2';
  var corTituloCardCervjaVinho =
    produto.categoriaProduto === 'Cervejas'
      ? 'text-xs font-weight-bold text-warning text-uppercase mb-1'
      : 'text-xs font-weight-bold text-vinhos text-uppercase mb-1';
  var corBadge = produto.categoriaProduto === 'Cervejas' ? 'warning' : 'danger';
  let nomeProdutoUpperCase = new String(produto.nomeProduto).toUpperCase();

  return (
    <Card className={`${classNameCard} p-3`}>
      <Card.Body>
        <Row className="no-gutters align-items-center">
          <Col className="mr-2">
            <h5 className="font-weight-bold text-gray-800 mb-2">
              {nomeProdutoUpperCase}
            </h5>
            <Badge bg={corBadge} text="dark" className="mb-2">
              {produto.categoriaProduto}
            </Badge>

            <div className="h6 mb-2 font-weight-bold text-gray-800">
              {produto.subCategoriaProduto + ' ' + produto.ml + 'ml'}
            </div>
            <div className="h6 mb-2 text-muted">
              De <s>{produto.precoDe}</s>
            </div>
            <div className="h5 mb-2 font-weight-bold text-primary">
              Por {produto.precoProduto}
            </div>
            <div className="h6 mb-0 font-weight-bold text-gray-800">
              Distância: {produto.distanciaProduto} <b>Km</b>
            </div>
            <div className="h6 mb-0 font-weight-bold text-gray-800">
              Cod Promoção: <b>{produto.codPromocao}</b>
            </div>

            {produto.tipoUsuario === 'estabelecimento' && (
              <Button
                variant="primary"
                size="md"
                className="my-3"
                onClick={ (event) => {
                 
                  // Abrir a nova aba imediatamente
                   window.open(produto.urlGooGleMaps, '_blank');
                  
                     apiAxios.post('/produto/addClick', {
                      idEstabelecimento: produto.idEstabelecimento,
                      idProduto: produto.idProduto,
                      userIsLogged: isAuthenticatedClient() ? true : false,
                      tokenUserLogged: isAuthenticatedClient()
                        ? getTokenIsAuthenticatedClient()
                        : null,
                      precoProduto: produto.precoProduto,
                      categoriaProduto: produto.categoriaProduto,
                      nomeProduto: produto.nomeProduto,
                      subCategoriaProduto: produto.subCategoriaProduto,
                    });

                   // window.open(produto.urlGooGleMaps, '_blank');
                  
                }}
              >
                Ir Buscar
              </Button>
            )}

            <div className="h6 mb-1 font-weight-bold text-gray-800">
              <a
                href={produto.linkWhatsApp}
                target="_blank"
                className="text-success d-flex align-items-center"
              >
                <FaWhatsapp className="mr-1" />
                Falar com vendedor
              </a>
            </div>
          </Col>

          <Col className="col-auto d-flex flex-column align-items-center">
            {produto.isPlanoAtivoEstabelecimento &&
              produto.isEstabelecimentoParceiroMotoristas && (
                <FaHandshake className="fa-2x text-primary mb-2" aria-label="Estabelecimento Parceiro" />
              )}
            {produto.categoriaProduto === 'Cervejas' ? (
              <FaBeer className="fa-2x text-warning" aria-label="Cerveja" />
            ) : produto.categoriaProduto === 'Vinhos' ? (
              <FaWineGlass className="fa-2x text-danger" aria-label="Vinho" />
            ) : (
              <FaCar className="fa-2x text-gray-300" aria-label="Outro Produto" />
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}


function getCardCarros(produto){

    var classNameCard = 'border-left-carros shadow h-100 py-2';
    var corTituloCard = 'text-xs font-weight-bold text-carros text-uppercase mb-1';
    
    return ( 
    <Card className={classNameCard}>
        <Card.Body>
            <Row className="no-gutters align-items-center">
                <Col className="mr-2">

                <div> {produto.nomeEstabelecimento}</div>
                <div className={corTituloCard}>
                        {produto.nomeProduto}</div>

                       
                       <div className="h6 mb-2 font-weight-bold text-gray-800">KM Rodados: {produto.kilometros_rodados}</div>
                       <div className="h6 mb-2 font-weight-bold text-gray-800">Ano Modelo: {produto.ano_modelo}</div>
                            
                        
                        <div className="h5 mb-2 font-weight-bold text-gray-800">Por {produto.precoProduto}</div>
                                            <div className="h6 mb-0 font-weight-bold text-gray-800">Dist: {produto.distanciaProduto } <b>Km</b></div>
                                            <div className="h6 mb-2 font-weight-bold text-gray-800">Ou {produto.distanciaEmMetros } <b>Metros</b></div>
                                            {produto.tipoUsuario === 'estabelecimento' ? 
                                            
                                            <div className="h6 mb-0 font-weight-bold text-gray-800">
                                                <a href={produto.urlGooGleMaps} target="_blank">Ir Buscar</a>
                                            </div>
                                                :
                                                ''
                                            }
                                            <div className="h6 mb-1 font-weight-bold text-gray-800">
                                                <a href={produto.linkWhatsApp} target="_blank">Falar com vendedor</a>
                                            </div>
                </Col>

               
               <div className="col-auto">
               <i className="fa-solid fa-car fa-2x text-gray-300"></i>
           </div>
               

             
            </Row>
        </Card.Body>
    </Card>
);

}
