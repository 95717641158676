
import { Col,Row, Container, Figure } from "react-bootstrap";
import GooglePlayApp from '../assets/images/google-play-app.webp';
import AppStore from '../assets/images/app-store-app.webp';

const SectionAppsGeoGet = (props) => {


    return (
      <section className="text-center bg-light py-5">
      <Container>
          <Row className="align-items-center gy-4">
              <Col xs={12}>
                  <h2 className="fw-bold mb-4">Baixe o App GeoGet e encontre a cerveja ou vinho ideal para você!</h2>
                  <p className="lead">Filtre por distância, preço e localização. Obtenha rotas de Cervejas e Vinhos facilmente.</p>
              </Col>
              <Col xs={12} sm={6}>
                  <Figure className="d-flex justify-content-center">
                      <Figure.Image
                          className="img-fluid shadow-sm"
                          alt="Download from Google Play"
                          src={GooglePlayApp}
                      />
                  </Figure>
              </Col>
              <Col xs={12} sm={6}>
                  <Figure className="d-flex justify-content-center">
                      <Figure.Image
                          className="img-fluid shadow-sm"
                          alt="Download from App Store"
                          src={AppStore}
                      />
                  </Figure>
              </Col>
          </Row>
      </Container>
  </section>
  
    )

}

export default SectionAppsGeoGet;