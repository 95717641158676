import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';  
import { v4 as uuidv4 } from 'uuid';
import React from "react";
import Col from 'react-bootstrap/Col';
import { Table } from 'react-bootstrap';
import moment from 'moment-timezone';
import { formatarPreco } from "../util/util";




const SectionEstatisticaUsuario = (props) => { 



    // Função para converter dia da semana de número para nome
function getDayName(dayNumber) {
    const days = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
    return days[dayNumber - 1];
  }

  const formatHour = (hour) => {
    return moment.tz({ hour }, 'America/Sao_Paulo').format('HH:00');
  };

    

  
return(

    <>
    <section >
      
     

      <Container>
     
      
      <Row>
     
     
        
       { (props.estatValorMedioProdutosRegiao ?  
        <Col xs={12} md={6}>
        
        <h2>Descubra os Melhores Preços na Sua Região: Comparativo de Bebidas! </h2>
        <Table responsive striped bordered hover>
  <thead>
    <tr>
      <th>Categoria</th>
      <th>Produto</th>
      <th>ml</th>
      <th>Preço Medio</th>
      <th>Min e Max</th>
    </tr>
  </thead>
  <tbody>
      

    
{
       props.estatValorMedioProdutosRegiao.slice(0, 15).map((entry, index) => (
            <tr key={index}>
              <td>{entry.categoria}</td>
              <td>{entry.nome}</td>
              <td>{entry.ml}</td>
              <td>{formatarPreco(entry.precoMedio)}</td>
              <td>
                {(entry.maiorPreco &&  entry.menorPreco ? 
              formatarPreco(entry.menorPreco) +' - '+formatarPreco(entry.maiorPreco) :
              'Somente 1 encontrado')}
              </td>
            </tr>
          ))
          }

          </tbody>
        </Table>
      
      </Col>
    :
    ''  
    )
     }

     {
      (props.estatProdutosMiasClicadosNaRegiao ? 
        
        <Col xs={12} md={6}>

<h2>Top 10 Produtos Mais Desejados nesta Região: </h2>
        <Table responsive striped bordered hover>
  <thead>
    <tr>
      <th>Categoria</th>
      <th>Produto</th>
      <th>Preço</th>
      <th>Distância</th>
    </tr>
  </thead>
  <tbody>
      

    
{
       props.estatProdutosMiasClicadosNaRegiao.map((entry, index) => (
            <tr key={index}>
              <td>{entry.categoria}</td>
              <td>{entry.nome_produto}</td>
              <td>{formatarPreco(entry.preco)}</td>
              <td>{entry.distance + ' KM'}</td>
            </tr>
          ))
          }

          </tbody>
        </Table>
      

        </Col>

        :
        ''
      )
     }
        </Row>

        </Container>   
	 
	   
    </section>
    </>



);

}

export default SectionEstatisticaUsuario;












