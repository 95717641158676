
import Spinner from 'react-bootstrap/Spinner';
 import Container from 'react-bootstrap/Container'
 import Row from 'react-bootstrap/Row';
 import Button from 'react-bootstrap/Button';

import { useEffect,useState } from 'react';


const SectionLibereSuaLocalizacao = (props) => {
	

	const [mensagem, setMensagem] = useState('Verificando suas Coordenadas...');

	useEffect(() => {

		if(props.permissionState === 'prompt'){
			setMensagem('O aplicativo precisa de permissão para acessar sua localização. Você verá um aviso solicitando permissão.');
		}
		else if(props.permissionState === 'denied'){
			setMensagem('O acesso à sua localização foi negado. Por favor, permita o acesso nas configurações do navegador para usar todos os recursos.');
		}else if(props.permissionState === 'granted' && props.statusGetLocaton === 'error'){
		setMensagem('Ocorreu um erro inesperado ao tentar acessar sua localização. Tente novamente .')
		
	}else if(props.permissionState === 'granted' && props.statusGetLocaton === 'timeout'){
		setMensagem('O pedido de localização expirou. Verifique sua conexão de rede ou GPS e tente novamente.')
		
	}else if(props.permissionState === 'granted' && props.statusGetLocaton === 'unavailable'){
		setMensagem('As informações de localização não estão disponíveis. Verifique se o GPS está ativado e tente novamente.')
		}

			 
	},[])

    return (
       
        <section>
	   <Container className='mt-4 mb-4'>
	   <h3 class="mb-5 text-center">{mensagem}</h3>
	   <Row className="row mt-3 centered">
                     


					
					 <Spinner animation="border" />
					
					 </Row>
		<Row>
		<div className="bg-image img-fluid imgLibereSuaLocalizacao">
</div>
		</Row>
		
	


	   </Container>
	  
	   </section>


    );
}

export default SectionLibereSuaLocalizacao;





