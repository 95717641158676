import Container from "react-bootstrap/Container";
import { MapContainer, TileLayer, Marker, Popup, Circle } from "react-leaflet";

import L from 'leaflet';

 
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('../../src/assets/images/leaflet/marker-icon-2x.png'),//leaflet/dist/images/marker-icon-2x.png
    iconUrl: require('../../src/assets/images/leaflet/marker-icon.png'),
    shadowUrl: require('../../src/assets/images/leaflet/marker-shadow.png')
}); 

const iconPersonLocation = new L.Icon({
  iconUrl: require('../../src/assets/images/leaflet/icon-person.png'),
  //iconRetinaUrl: require('../../src/assets/images/leaflet/icon-person-2x.png'),
  iconSize: [64, 64],
    iconAnchor:[32, 64], // point of the icon which will correspond to marker's location
 
    popupAnchor:[0, -57] // point from which the popup should open relative to the iconAnchor
});

const iconStoreLocation = new L.Icon({
  iconUrl: require('../../src/assets/images/leaflet/icon-store-non-bg-64-64.png'),
  
  iconSize: [64, 64],
    iconAnchor:   [17, 46], // point of the icon which will correspond to marker's location
 
    popupAnchor:  [15, -35] // point from which the popup should open relative to the iconAnchor
});

const iconPersonUnique = new L.Icon({
  iconUrl: require('../../src/assets/images/leaflet/locator-40-40.png'),
  
  iconSize: [40,40],
    iconAnchor:   [17, 46], // point of the icon which will correspond to marker's location
 
    popupAnchor:  [15, -35] // point from which the popup should open relative to the iconAnchor
});

const iconMapMarkerWine = new L.Icon({

  iconUrl: require('../../src/assets/images/leaflet/map_marker_winebar.png'),
  iconSize: [32,37],
  iconAnchor:   [16, 37], // point of the icon which will correspond to marker's location
  popupAnchor:  [0, -30] // point from which the popup should open relative to the iconAnchor

});

const iconMapMarkerBeer = new L.Icon({

  iconUrl: require('../../src/assets/images/leaflet/map_marker_beergarden.png'),
  iconSize: [32,37],
  iconAnchor:   [16, 37], // point of the icon which will correspond to marker's location
  popupAnchor:  [0, -30] // point from which the popup should open relative to the iconAnchor

});

const iconMapMarkerPerson = new L.Icon({

  iconUrl: require('../../src/assets/images/leaflet/map_marker_person.png'),
  iconSize: [32,37],
  iconAnchor:   [16, 37], // point of the icon which will correspond to marker's location
  popupAnchor:  [0, -30] // point from which the popup should open relative to the iconAnchor

});

const iconMapMarkerCarStory = new L.Icon({

  iconUrl: require('../../src/assets/images/leaflet/map_marker_carStory.jpeg'),
  iconSize: [32,37],
  iconAnchor:   [16, 37], // point of the icon which will correspond to marker's location
  popupAnchor:  [0, -30] // point from which the popup should open relative to the iconAnchor

});



const iconMapMarkerCar = new L.Icon({

  iconUrl: require('../../src/assets/images/leaflet/map_marker_car.png'),
  iconSize: [32,37],
  iconAnchor:   [16, 37], // point of the icon which will correspond to marker's location
  popupAnchor:  [0, -30] // point from which the popup should open relative to the iconAnchor

});




const iconStore = L.divIcon({
  html: '<i class="fa-solid fa-store"></i>',
  iconSize: [30, 30] 
});

const iconPerson = L.divIcon({
  html: '<i class="fa-sharp fa-solid fa-person"></i>',
  iconSize: [20, 20] 
});



const MapLeaflet = (props) => {


    function createIcon(url) {
      return new L.Icon({
        iconUrl: url,
      });
    }

    function getMarkersIcon(usuarioLead) {
     /*
      var isUsuarioLogado = (usuarioLead.nome == null || usuarioLead.nome == 'undefined' ?  false  :  true );

      if(isUsuarioLogado && usuarioLead.produtos_venda.length > 0){
        
        return iconMapMarkerCar;
      }
      else{
        return iconMapMarkerPerson;
      }*/
      return iconMapMarkerPerson;
    
          }
          function getWhatsApp(telefone,nomeProduto,preco){
            telefone = telefone.replaceAll('(','').replaceAll(')','').replaceAll('-','').replaceAll(' ','');
            const linkWhatsApp = 
    'https://api.whatsapp.com/send?phone=55'+telefone+'&text=Ol%C3%A1,%20estou%20interessado%20no%20'+nomeProduto+'%20anunciado por R$'+preco+'%20publicados%20na%20GeoGet...';
    
    var tagWhatsApp = <a href={linkWhatsApp} target="_blank">Contato</a>;
    
    return tagWhatsApp;
          }

          function getMarkerIcon(mapParaEstabelecimento) {
            if(mapParaEstabelecimento){
                  //return createIcon("https://user-images.githubusercontent.com/1596072/85960867-3baf9700-b9af-11ea-854e-7ef6e656d447.png");
                return iconStoreLocation;
                }else{
                  //return createIcon("https://user-images.githubusercontent.com/1596072/85960806-0145fa00-b9af-11ea-91ab-a107d0a64b66.png");
                   return iconPersonLocation; 
                }
          
                }

                
     return (
        <>
       
        
        { (
           props.centroMapaPosicaoUser !== null &&
           props.centroMapaPosicaoUser  !== ''   

            ?
            
            <>

            <section key='222333412' className="text-center" style={{"padding-top": "3rem", "padding-bottom": "3rem", "height": "100vh"}}>   
            <Container>             
            

            <MapContainer style={{ height: "80vh" }} center={props.centroMapaPosicaoUser} zoom={(props.mapParaEstabelecimento ? 15 : 16)} scrollWheelZoom={false}>
<TileLayer
url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
/>
 

{
  (
    props.mapParaEstabelecimento ?

    <Marker position={props.centroMapaPosicaoUser} 
    icon={ getMarkerIcon(props.mapParaEstabelecimento) }>
  <Popup>{props.dataUserCenter.nome}</Popup>

  <Circle 
    center={{lat:props.centroMapaPosicaoUser[0], lng: props.centroMapaPosicaoUser[1]}}
    fillColor="Blue" 
    radius={500}/>
</Marker>

    :

    <Marker 
        position={props.centroMapaPosicaoUser} 
      icon={ getMarkerIcon(props.mapParaEstabelecimento) } >      
           <Popup>
         {props.dataUserCenter.nome}  <br /> 
    </Popup>
    <Circle 
                  center={{lat:props.centroMapaPosicaoUser[0], lng: props.centroMapaPosicaoUser[1]}}
                  fillColor="Blue" 
                  radius={500}/>
        </Marker>

  )
}

 
        {
        
        (
          props.arrayMarkers != null && props.arrayMarkers.length > 0 ?

          props.arrayMarkers.map((maker, index) => {
            /*var isUsuarioLogado = (maker.nome == null || maker.nome == 'undefined' ?  false  :  true );
            
            var linkWhatsApp = '';
            if(isUsuarioLogado){
              linkWhatsApp = getWhatsApp(maker.celular,maker.produtos_venda[0].nome_produto,maker.produtos_venda[0].preco);
            }*/
            return <Marker 
            key={index} 
            position={{lat:maker.latitude,lng:maker.longitude}}
            icon={getMarkersIcon(maker)} 

            >
               <Popup>
             
              
                 
             {
            
             ('Apenas a ' + (maker.distanciaEmKilometros * 1000).toFixed(0)  + ' Metros de distância e à procura de Cervejas e Vinhos. Não perca essa oportunidade!')
              
            }
          
          
           
           
          
        </Popup>
            </Marker>;
          })

          :
''
        )
        
       }

</MapContainer>

</Container>

</section>




          </>
            :

            ''
        )  
                   
                }
        
       
        </>
     );

}

export default MapLeaflet;