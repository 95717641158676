const getCoordinates = async () => {
  try {
    // Verifica o status da permissão de geolocalização
    const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

    // Define o status inicial como 'unknown'
    let permissionState = 'unknown';

    if (permissionStatus.state === 'granted') {
      permissionState = 'granted';
    } else if (permissionStatus.state === 'denied') {
      permissionState = 'denied';
    } else if (permissionStatus.state === 'prompt') {
      permissionState = 'prompt';
    }

    return new Promise((resolve) => {
      if (!navigator.geolocation) {
        resolve({
          status: 'unsupported',
          permissionState,
          latitude: null,
          longitude: null,
          message: 'Geolocation is not supported by this browser.',
        });
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          resolve({
            status: 'success',
            permissionState,
            latitude,
            longitude,
            message: 'Geolocation successful.',
          });
        },
        (error) => {
          let status;
          let message;

          switch (error.code) {
            case error.PERMISSION_DENIED:
              status = 'denied';
              message = 'User denied the request for Geolocation.';
              break;
            case error.POSITION_UNAVAILABLE:
              status = 'unavailable';
              message = 'Location information is unavailable.';
              break;
            case error.TIMEOUT:
              status = 'timeout';
              message = 'The request to get user location timed out.';
              break;
            default:
              status = 'error';
              message = 'An unknown error occurred.';
              break;
          }

          resolve({
            status,
            permissionState,
            latitude: null,
            longitude: null,
            message,
          });
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    });
  } catch (error) {
    // Caso o browser não suporte a API Permissions ou ocorra outro erro
    return {
      status: 'error',
      permissionState: 'unknown',
      latitude: null,
      longitude: null,
      message: error.message || 'An error occurred while checking permissions.',
    };
  }
};

export default getCoordinates;
