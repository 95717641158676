import React, {useEffect, useState, useRef } from "react";
import Container  from "react-bootstrap/Container";
import Row  from "react-bootstrap/Row";
import Form  from "react-bootstrap/Form";
import Col  from "react-bootstrap/Col";
import Card  from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Label from "react-bootstrap/FormLabel";
import { Tabs, Tab,Alert  } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';
import { object, string } from "yup";
import { atualizaDataUserEstabelecimentoLocalStorage, getDataEstabelecimentoLocalStorage, getTokenIsAuthenticatedEstabelecimento, getTokenJwtFormLocalStorage, isAuthenticated, logout } from "../services/auth";
import apiAxios from "../services/api";
import { Modal } from 'react-bootstrap';
import { montaAreaProdutos } from "../hooks/montaProdutos";
import FileDownload from "js-file-download";
import { categorias } from "../hooks/useCategorias";
//import { socketEstabelecimento } from "../context/socket";
import { socketEstabelecimento } from "../context/socket";
import {format,parseISO } from "date-fns";
import MapLeaflet from "./mapLeaflet";
import SectionEstatisticasEstabelecimento from "./SectionEstatisticasEstabelecimento";
import { controleAbasAbertasLocalStorage, converterDecimal128ParaPrecoBrasil} from "../util/util";
import { getCategoriasBebidas } from "../util/Categorias";
import TypewriterIA from "./TypewriterIA";
import SectionPrecoPlanoEstabelecimentos from "./SectionPrecoPlanoEstabelecimentos";
import { v4 as uuidv4 } from 'uuid';
import SectionQuestionarioComChatIAEstabelecimentos from "./SectionQuestionarioComChatIAEstabelecimentos";


//import axios from "axios";


function MyVerticallyCenteredModal(props) {

  return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          {props.titleModal}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{props.titleTextBodyModal}</h4>
          <p>
          {props.textBodyModal}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );


}
 
const downloadQrCode = (e) => {

  
  e.preventDefault();
  var urlDownloadQrcode = "/estabelecimento/downloadQRCode";
  apiAxios({
    url:urlDownloadQrcode,
    method:"POST",
    responseType:"blob"
  }).then((res) => {
    FileDownload(res.data,"qrcode.png");
  })

  ;

}

const currencyMask = (e:React.ChangeEvent<HTMLInputElement>) => {
  let value = e.target.value;
  value = value.replace(/\D/g,"");
  value = value.replace(/(\d)(\d{2})$/, "$1,$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
  e.target.value = value;
  return e;
}

const currencyMaskTransform = (value:string) =>{
  
  value = value.replace(/\D/g,"");
  value = value.replace(/(\d)(\d{2})$/, "$1, $2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
   
  return value;
}

const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//sempre que algum campo é alterado é chama esta funcao
}

const SectionEdicaoProdutos = () => {
 
  
  const [modalShow, setModalShow] = React.useState(false);
  const [textBodyModal, setTextBodyModal] = React.useState('');
  const [titleTextBodyModal, setTitleTextBodyModal] = React.useState('');
  const [titleModal, setTitleModal] = React.useState('');
  const[nomeUsuario , setNomeUsuario] = useState('');
  const [idProdutoAtualizar , setIdProdutoAtualizar] = useState('');
  const [qrCode , setQrcode] = useState('');
  const [produtos, setProdutos] = useState([]);
  const [areaProdutos , setAreaProdutos] = useState([]);
  const [alteracaoProduto , setAlteracaoProduto] = useState(false);
  const [selectedCategoria,setSelectedCategoria] = useState('');
  const [tokenJwtForm , setTokenJwtForm] = useState('');
  const [textTitleIA, setTextTitleIA] = useState(null);
  const [isCheckedIsEstabelecimentoParceiroMotoristas,setIsCheckedIsEstabelecimentoParceiroMotoristas] = useState(false);
  const [valorCampoContaCLiente,setValorCampoContaCliente] = useState('');
  const [objectComissaoMotorista,setObjectComissaoMotorista] =useState(null);
  const [dadosLocalizacao, setDadosLocalizacao] = useState({

    loaded: false,
    coords: '',
    isGeolocationAvailable: false,
    isGeolocationEnabled : false,
    error: false

});

const scrollAreaLogadoRef = useRef(null);

const [currentLocation, setCurrentLocation] = useState({

  latitude:'',
  longitude:''

});

const [numeroClientesPorPerto , setNumeroClientesPorPerto] = useState(1);
const [numeroClientesLogados , setNumeroClientesLogados] = useState(0);
const [leads , setLeads] = useState([]);
const [estatisticasEstabelecimento , setEstatisticasEstabelecimento] = useState(null);
const [estatisticaValorMedioProdutosRegiao , setEstatisticaValorMedioProdutosRegiao] = useState(null);
const [estatisticaCliquesToMapPordutos , setEstatisticaCliquesToMapPordutos] = useState(null);
const [showModalConfirmComissaoMotorista , setShowModalConfirmComissaoMotorista] = useState(null);

const [codigoMotoristaConsultado , setCodigoMotoristaConsultado] = useState(null);
const [appMotoristaConsultado , setAppMotoristaConsultado] = useState(null);
const [valorContaPosConsulta , setValorContaPosConsulta] = useState(null);
const [nomeMotoristaConsultado,setNomeMotoristaConsultado] = useState(null);


const schemaFormComissaoMotorista = object({ 
  valorContaCliente: string().required("Obrigatório"),
  codigoMotoristaComissao : string().required("Obrigatório")
  
 });

const {
  register: registerComissaoMotorista, 
  handleSubmit: onSubmitComissaoMotorista, 
  formState: {errors: errorsComissaoMotrista},
  reset : resetFormComissaoMotorista,
  setFocus : setFocusFormComissaoMotorista
} = useForm({
  mode: "onChange",
  resolver: yupResolver(schemaFormComissaoMotorista)
});


const schemaFormDescontoCreditoMotorista = object({ 
  valorTotalContaMotorista: string().required("Obrigatório"),
  valorUtilizarMotorista : string().required("Obrigatório"),
  codigoMotristaDesconto: string()
    .required("Obrigatório")
    .min(4, "O código do motorista deve ter pelo menos 4 caracteres")
    .max(12, "O código do motorista deve ter no máximo 12 caracteres")
  
 });

const {
  register: registerDescontoCreditoMotorista, 
  handleSubmit: onSubmitFormDescontoCreditoMotorista, 
  formState: {errors: errorsDescontoCreditoMotrista},
  reset : resetFormDescontoCreditoMotorista,
  setFocus : setFocusFormDescontoCreditoMotorista
} = useForm({
  mode: "onChange",
  resolver: yupResolver(schemaFormDescontoCreditoMotorista)
});

const handleSubmitComissaoMotoristaModal = () => { 

  setShowModalConfirmComissaoMotorista(false);
  const url ='/estabelecimento/comissaoIndicacaoMotorista';

  apiAxios.post(url, objectComissaoMotorista)
   .then((response) => {
    
    if(response.data.success){
      alert(response.data.message);
      resetFormComissaoMotorista();
    }
    else{
      alert(response.data.message);
     
    }
   
    
   });

}

const handleSubmitComissaoMotoristaForm = (data:any) => { 
   
if(!data.codigoMotoristaComissao || !data.valorContaCliente ){
  alert('Por favor, preencher os campos Obrigatorios');
  return;
}

  setObjectComissaoMotorista({
    'codigoMotoristaComissao' : data.codigoMotoristaComissao    ,
    'valorContaCliente': data.valorContaCliente
   });

//chamar a modal aqui
const url ='/estabelecimento/consultaMotorista';

apiAxios.post(url, {
  'codigoMotorista':data.codigoMotoristaComissao
})
   .then((response) => {
    
    if(response.data.success){ 

      if(response.data.motorista.status === 'A'){
        setCodigoMotoristaConsultado(response.data.motorista.codigoMotorista);
      setAppMotoristaConsultado(response.data.motorista.app);
      setValorContaPosConsulta(data.valorContaCliente);
      setNomeMotoristaConsultado(response.data.motorista.nomeMotorista);
  
      setShowModalConfirmComissaoMotorista(true);
      }
      else{
        alert('Esse Motorista está bloqueado');
      }

      
    }
    else{
      alert(response.data.message);
    }
    
    
    
   });

  
}

async function atualizaSubCategorias(categoriaSelecionada){

   // Encontra e define as subcategorias com base na categoria selecionada
   const categoriaObj = categorias.find(
    (cat) => cat.categoria === categoriaSelecionada
  );
  const novasSubCategorias = categoriaObj ? categoriaObj.subcategorias : [];
  setSubCategoriasEscolhidas(novasSubCategorias);

}

  const handleCategoriaUpdate = (event) => {
    //setSelectedCategoria(event.target.value);
    const categoriaSelecionada = event.target.value;
    atualizaSubCategorias(categoriaSelecionada);
  }

  //const sub_categorias_escolhidas = categorias.filter(x => x.categoria === selectedCategoria);
  
  function montaPordutosTela ()  {

    
    if(produtos.length > 0){

      const arr = mountProdutosPublicados(produtos);

    return arr;

    }
    else{
      return [];
    }
    

  }

  const mountProdutosPublicados = (produtos) =>{

    const arrayReturn = [];

    var arrayComProdutosPublicadosCards = produtos.map((prod) =>
     
        <Col sm={6} className="mb-4" key={uuidv4()}>
            {
             getCardProdutosPublicados(prod)
             }
            </Col>
         
);

var rr =( <div><Row className="mt-4">
           
    {arrayComProdutosPublicadosCards}
   
</Row></div>);

arrayReturn.push(rr);

    return arrayReturn;

}

  function getCardProdutosPublicados(produto){

    return (
      <Card>
      <Card.Body>
        <div>
          <h5 className="card-title">{produto.nome_produto}</h5>
          <p className="card-text">{produto.descricao || '-'}</p>
          <p className="card-text">{produto.marca || '-'}</p>
          <p className="card-text">
            <i className="fas fa-flask"></i> {produto.ml + 'ml'}
          </p>
          <p className="card-text">
            <i className="fas fa-tags"></i> {produto.categoria || '-'}
          </p>
          <p className="card-text">{produto.sub_categoria || '-'}</p>
    
          {/* Preço com Destaque */}
          <div style={{ backgroundColor: '#d4edda', padding: '10px', borderRadius: '5px' }}>
            <p className="card-text">
              Preço De: <s>R${converterDecimal128ParaPrecoBrasil(produto.precoDe)}</s>
            </p>
            <p className="card-text">
              <strong>Por: R${converterDecimal128ParaPrecoBrasil(produto.preco)}</strong>
            </p>
          </div>
    
          {/* Quantidade para Promoção */}
          <p className="card-text">Por Unidade: {produto.qtdeParaPromocao || '-'}</p>
    
          <p className="card-text">
            Promoção Válida até: {format(parseISO(produto.dataVencimentoPromocao), 'dd/MM/yyyy')}
          </p>
    
          {/* Botões com Feedback Visual */}
          <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
            <Button 
              variant="primary" 
              type="button" 
              onClick={alterarProduto} 
              id={produto._id} 
              data-produto={JSON.stringify(produto)}
              style={{ transition: 'transform 0.2s' }}
              onMouseOver={(e) => e.target.style.transform = 'scale(1.05)'}
              onMouseOut={(e) => e.target.style.transform = 'scale(1)'}
            >
              <i className="fas fa-edit"></i> Alterar
            </Button>
            
            <Button 
              variant="danger" 
              type="button" 
              onClick={apagarProduto} 
              id={produto._id} 
              data-nomeproduto={produto.nome_produto}
              data-descricao={produto.descricao} 
              data-marca={produto.marca}
              data-ml={produto.ml}
              data-precode={converterDecimal128ParaPrecoBrasil(produto.precoDe)}
              data-precopor={converterDecimal128ParaPrecoBrasil(produto.preco)}
              data-categoria={produto.categoria}
              data-subcategoria={produto.sub_categoria}
              data-codigopromocao={produto.codigo_promocao}
              data-qtdeParaPromocao={produto.qtdeParaPromocao}
              data-dataVencimentoPromocao={produto.dataVencimentoPromocao}
              style={{ transition: 'transform 0.2s' }}
              onMouseOver={(e) => e.target.style.transform = 'scale(1.05)'}
              onMouseOut={(e) => e.target.style.transform = 'scale(1)'}
            >
              <i className="fas fa-trash"></i> Apagar
            </Button>
          </div>
        </div>
      </Card.Body>
    </Card>
    
    )


}


  useEffect(() => { // esse é responsável em pegar as alterações
    
     // var areaMontada = montaAreaProdutos(produtos);
    const qtdeProdutos = produtos.length;
    const arProds = montaPordutosTela ();

    setAreaProdutos(arProds) ;
    setTokenJwtForm(getTokenJwtFormLocalStorage());
    //console.log('AQUI SETAR O JWT PARA FORM DE EDICAO DE PRODUTOS => '+ getTokenJwtFormLocalStorage());
  }, [produtos]);


  const setarDataDefault = () =>{

    var today = new Date().toISOString().split('T')[0];

 
document.getElementById("validade_promocao").setAttribute("min", today);



  }

  function consultaEstabelecimento(){
    
    const url ='/estabelecimento/consultaEstabelecimento';

    apiAxios.post(url, {
      'consulta' : true
     })
     .then((response) => {
      
      setIsCheckedIsEstabelecimentoParceiroMotoristas(response.data.estabelecimento.isParticipaRedeMotoristasAppTaxista);
      
      
     });


  }

  useEffect(() => { 
    
   

    socketEstabelecimento.connect();
  consultaEstabelecimento();
    setarDataDefault();
    
    setAlteracaoProduto(false);

    const dadosUsuario = getDataEstabelecimentoLocalStorage();
     setNomeUsuario(dadosUsuario.nome);

    var urlConsultaProdutos = "/produto/consultar";

     apiAxios.post(urlConsultaProdutos, {
      'consultaProdutos' : true
     })
     .then((response) => {
      
      //console.log(response);

      if(response.data.success && response.data.auth ){

       if(response.data.qrcode !== null && response.data.qrcode !== undefined){setQrcode(response.data.qrcode);} 
        //imgQrcode.src = response.data.qrcode;
       

        if(
          response.data.estatisticaQtdeLeadsPeriodoRaio !== null && 
          response.data.estatisticaQtdeLeadsPeriodoRaio !== undefined && 
          Object.keys(response.data.estatisticaQtdeLeadsPeriodoRaio).length > 0 
        ){
          setEstatisticasEstabelecimento(response.data.estatisticaQtdeLeadsPeriodoRaio);
        }

        if(
          response.data.estatisticaValorMediosProdutosRegiao !== null &&
          response.data.estatisticaValorMediosProdutosRegiao !== undefined &&
          Object.keys(response.data.estatisticaValorMediosProdutosRegiao).length > 0 
         ){
          setEstatisticaValorMedioProdutosRegiao(response.data.estatisticaValorMediosProdutosRegiao);
        }

        if(response.data.estatisticasClickToMap !== null &&
          response.data.estatisticasClickToMap !== undefined &&
          Object.keys(response.data.estatisticasClickToMap).length > 0 ){
          setEstatisticaCliquesToMapPordutos(response.data.estatisticasClickToMap);
        }
       
        

        if( Array.isArray(response.data.produtosEstabelecimento) ){

          //aqui fazer a logica de setar os produtos na tela
       var produtos = response.data.produtosEstabelecimento;
       setProdutos(produtos);

       }

      // atualizaDataUserEstabelecimentoLocalStorage(response.data.dataUser);

      }
      
      
     })
     .catch((erro) => {
       
      if(!erro.response.data.success && !erro.response.data.auth && erro.response.data.message === 'Token Expirado'){
        sair();
      }

      //console.log(erro);

     });

     const onErrorLocation = (error) => {
      console.error(error);
      setDadosLocalizacao({
        
        loaded: true,
        coords: '',
        isGeolocationAvailable: false,
        isGeolocationEnabled : false,
        error: true
      
    });
    };

     const handleCurrentLocation = async (location) => { 
      
      
      setDadosLocalizacao({
        
          loaded: true,
          coords: location.coords,
          isGeolocationAvailable: true,
          isGeolocationEnabled : true,
          error: false
        
      });

      const posicaoAtual = {latitude:location.coords.latitude, longitude:location.coords.longitude};
        
        setCurrentLocation(posicaoAtual);
        //Executar aqui a verificação com o socket

      
      
  
          const data = {
  
              //'latitude' : position.latitude, 
              //'longitude' : position.longitude,
              
              'latitude' : getDataEstabelecimentoLocalStorage().latitude, 
              'longitude' : getDataEstabelecimentoLocalStorage().longitude,
              'distanciaMetros' : '3000',
              'produtos' : (getDataEstabelecimentoLocalStorage().produtos !== null && 
              getDataEstabelecimentoLocalStorage().produtos !== undefined ? getDataEstabelecimentoLocalStorage().produtos  : []),
              'categorias' : (getDataEstabelecimentoLocalStorage().categorias !== null &&
              getDataEstabelecimentoLocalStorage().categorias !== undefined ? getDataEstabelecimentoLocalStorage().categorias : [])
  
          };
  
            
            //AQUI QUE A MAGICA ACONTECE
            console.log('ENVIANDO EMIT: estabelecimentoGetLeadsProximos ');
            socketEstabelecimento.emit("/estabelecimentoGetLeadsProximos", data);
        

      
    };

     const executeSetIntervalGeolocation = async () => {


      if (!navigator?.geolocation) {
        return;
      }

      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
       
      navigator.geolocation.getCurrentPosition(handleCurrentLocation, onErrorLocation,options);

    }
    
      const interval = setInterval(executeSetIntervalGeolocation, 5000);
    
       controleAbasAbertasLocalStorage(true,false);
    //funcao para detectar o fechamento da aba ou janela
   const handleUnload = async () =>{ 
    
    
    
    localStorage.removeItem('socketIdPingEstabelecimentoLogado');
    socketEstabelecimento.disconnect(true);
    clearInterval(interval);
    
    
   }

   window.addEventListener('beforeunload',handleUnload);

    window.scrollTo({
      top: scrollAreaLogadoRef.current.offsetTop,
      behavior: "smooth"
    });
    

    
    
    return () =>{ // return é a função de cleanup 
     
        socketEstabelecimento.disconnect(true);
        localStorage.removeItem('socketIdPingEstabelecimentoLogado');
        clearInterval(interval);
        window.removeEventListener('beforeunload',handleUnload);
        
      
      //socketEstabelecimento.off("estabelecimentoGetLeadsProximos");
    };

  }, []);

  socketEstabelecimento.on('estabelecimentoGetLeadsProximos', data =>{ 

   // console.log(JSON.stringify(data));
   
    const validationTokenSocket = data.validationTokenSocket;
    

    if(!validationTokenSocket){

      sair();


    }

    agrupaLeadsConsultados (data);
    
    
            });
    
    function agrupaLeadsConsultados (data){

      const leadsConsultados = data.resultadoConsulta.pingsProximos;
      const leadsLogados = data.resultadoConsulta.usuariosLogadosProximos;
      const arrayLeadsConsutados = [];

      if(leadsConsultados !== null && leadsConsultados !== undefined){
        setNumeroClientesPorPerto(leadsConsultados.length);
        
        leadsConsultados.forEach((lead: any) => {
          arrayLeadsConsutados.push(lead);
        });
        
        
        
      }
  
      if(leadsLogados !== null && leadsLogados !== undefined ){
        setNumeroClientesLogados(leadsLogados.length);
        leadsLogados.forEach((lead: any) => {
          arrayLeadsConsutados.push(lead);
        });
        
      }
  
      setLeads(arrayLeadsConsutados);

    }
  
    const handleSubmitComsumacaoMotorista = (data:any) => {
      
      if(!data.valorTotalContaMotorista || data.valorTotalContaMotorista === '0,00' ||
        !data.valorUtilizarMotorista || data.valorUtilizarMotorista === '0,00' ||
        !data.codigoMotristaDesconto
      ){
        alert('Campos Obrigatorios nao informados');
        return;
      }

       // Captura os valores dos campos diretamente
    const valorContaMotorista = data.valorTotalContaMotorista ;
    const valorUtilizarMotorista = data.valorUtilizarMotorista ;
    const codigoMotoristaConsumacao = data.codigoMotristaDesconto;


    const url ='/estabelecimento/utilizacaoSaldoMotorista';

  apiAxios.post(url, {
    'valorContaMotorista':valorContaMotorista,
    'valorUtilizarMotorista':valorUtilizarMotorista,
    'codigoMotoristaConsumacao':codigoMotoristaConsumacao
  })
   .then((response) => {
    if(response.data.success){
      alert(response.data.message);
      resetFormDescontoCreditoMotorista();
    }
    else{
      alert(response.data.message);
    }
    
    
   });
      
      
    };

const imgQrcode = useRef();


const executeScrollAtualizacao = () => {
  window.scrollTo({
    top: scrollAreaLogadoRef.current.offsetTop,
    behavior: "smooth"
  });
}   ;


const schemaFormNovoEdicao = object().shape({
  nome_produto:string().required("Nome Obrigatório").min(5,"Minimo de 5 caracteres").max(20,'Máximo 20 caracteres'),
  marca:string().required("Marca Obrigatório").min(6,'Minimo de 6 caracteres').max(25,'Maximo de 6 caracteres'),
  ml:string().required("ML Obrigatório").min(2,'Minimo de 2 caracteres').max(3,'Maximo de 3 caracteres'),
  qtdeParaPromocao:string().required("Obrigatorio"),
  descricao:string().required('Descrição Obrigatório').min(10,'Mínimo 10 caracteres').max(30,'Máximo 30 caracteres'),
  precoDe:string().required('Preço Antigo Obrigatorio'),
  preco:string().required('Preço Atual Obrigatório'),
  categoria:string().required('Categoria Obrigatório'),
  sub_categoria:string().required('Sub-Categoria Obrigatório'),
  validade_promocao:string().required('Validade Obrigatorio'),
  codigo_promocao: string().required('Codigo Obrigatorio')
  
  
});

  const { 
    register, 
    handleSubmit : onSubmitNovoEdicao, 
    setValue: setValuesFormNovoEdicao,
    watch: watchFormNovoEdicao, 
    formState: { errors },
    reset : resetFormNovoEdicao, 
  } = useForm({resolver:yupResolver(schemaFormNovoEdicao),
    defaultValues: {
      categoria: '',
      sub_categoria: 'Selecione_a_Categoria'
      // Outros valores padrão...
    },
  });

  const [subCategoriasEscolhidas, setSubCategoriasEscolhidas] = useState([]);


  const handleSubmitNovoEdicao = (data:any) => {
    
    
    if(!alteracaoProduto){
      //novo

    //  console.log('Novo produto : '+data);

    const urlNovoProduto = "/produto/novo";

    apiAxios.post(urlNovoProduto, {
      
      'nome_produto' : data.nome_produto,
      'categoria' : data.categoria,
      'sub_categoria' : data.sub_categoria,
      'descricao' : data.descricao,
      'marca' : data.marca,
      'ml' : data.ml,
      'preco' : data.preco,
      'precoDe' : data.precoDe,
      'codigoPromocao' : data.codigo_promocao,
      'qtdeParaPromocao' : data.qtdeParaPromocao,
      'dataVencimentoPromocao' : data.validade_promocao,
      'tipo_estabelecimento' : getDataEstabelecimentoLocalStorage().tipo_estabelecimento

     })
     .then((response) => {
      

        if(response.data.success){
          
          resetFormNovoEdicao();

          setTitleModal('Produto Cadastrado !');
          if(response.data.qtdeUsuariosInformados > 0){
            setTextBodyModal('Seu produto foi Cadastrado e já foi enviado um alerta para '+response.data.qtdeUsuariosInformados+' clientes próximos ao seu estabelecimento');
          }
          else{
            setTextBodyModal('Seu produto foi Cadastrado com Sucesso, agora os clientes próximos ao seu estabelecimento serão avisados');
          }
          setTitleTextBodyModal('Cadastro realizado, os clientes próximos ao seu estabelecimento, estão sendo avisados');
          
          setModalShow(true);

         

        if(Array.isArray(response.data.produtosEstabelecimento) ){

          //aqui fazer a logica de setar os produtos na tela
          var produtos = response.data.produtosEstabelecimento;
          setProdutos(produtos);
    
        }


        }
        else{
          alert(response.data.message);
        }
        
      
       
      console.log(response);

     })
     .catch((erro) => {
       
      if(!erro.response.data.success && !erro.response.data.auth && erro.response.data.message === 'Token Expirado'){
        sair();
      }

       console.log(erro);
      
     });



    }
    else{
      //Atualizar

      const urlAtualizarProduto = "/produto/atualizar";

    apiAxios.post(urlAtualizarProduto, {
      
      'idProduto' : idProdutoAtualizar,
      'nome_produto' : data.nome_produto,
      'categoria' : data.categoria,
      'sub_categoria' : data.sub_categoria,
      'descricao' : data.descricao,
      'marca' : data.marca,
      'ml' : data.ml,
      'preco' : data.preco,
      'precoDe' : data.precoDe,
      'qtdeParaPromocao' : data.qtdeParaPromocao,
      'codigoPromocao' : data.codigo_promocao,
      'dataVencimentoPromocao' : data.validade_promocao


     })
     .then((response) => {
      

        if(response.data.success){
          
          resetFormNovoEdicao();

         
          if(Array.isArray(response.data.produtosEstabelecimento) ){

            //aqui fazer a logica de setar os produtos na tela
            var produtos = response.data.produtosEstabelecimento;
            setProdutos(produtos);
      
          }

          setTitleModal('Produto Atualizado !');
          if(response.data.qtdeUsuariosInformados > 0){
            setTextBodyModal('Seu produto foi Atualizado, clientes próximos ao seu estabelecimento foram avisados');
          }
          else{
            setTextBodyModal('Seu produto foi Atualizado com Sucesso, agora os clientes próximos ao seu estabelecimento serão avisados');
          }
          setTitleTextBodyModal('Atualização realizada, os clientes próximos ao seu estabelecimento, estão sendo avisados');
          
          setModalShow(true);


          
        }

       
      setAlteracaoProduto(false);
       
      console.log(response);

     })
     .catch((erro) => {
       
      if(!erro.response.data.success && !erro.response.data.auth && erro.response.data.message === 'Token Expirado'){
        sair();
      }

       console.log(erro);
      
     });


    }
    
    


};

const handleCheckboxChange = (isChecked) => {
 

  var url  = "/estabelecimento/isParticipaRedeMotoristasAppTaxista";
 

  apiAxios.post(url, {
    'isEstabelecimentoParceiroMotoristas' : isChecked
   })
   .then((response) => {
    
    setIsCheckedIsEstabelecimentoParceiroMotoristas(response.data.estabelecimento.isParticipaRedeMotoristasAppTaxista);
    alert(response.data.message);
    
    
   });
};


const apagarProduto = event => {
  //alert('APAGAR :'+event.currentTarget.id);
  setAlteracaoProduto(false);

  resetFormNovoEdicao();
  
  executeScrollAtualizacao();
  var urlRemoverProduto = "/produto/remover";

  apiAxios.post(urlRemoverProduto, {
    'idProduto' : event.currentTarget.id
   })
   .then((response) => {
    
    if(response.data.success && response.data.auth   ){

      if(Array.isArray(response.data.produtosEstabelecimento)){

         //aqui fazer a logica de setar os produtos na tela
      var produtos = response.data.produtosEstabelecimento;
      setProdutos(produtos);

      }

      
     

    }
    

   })
   .catch((erro) => {
     
    console.log(erro);

   });

}

const handleChangeCampoContaCliente = (event) => {
  // Remove caracteres não numéricos
  let input = event.target.value.replace(/\D/g, '');

  // Divide o valor por 100 para lidar com as casas decimais
  const numericValue = parseFloat(input) / 100;

  // Formata o valor como moeda brasileira
  const formattedValue = numericValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

  setValorCampoContaCliente(formattedValue);
};

const sair = () => {

  logout();
  window.location.reload(false);


}

const alterarProduto = async(event) => { 
 
  const produtoData = JSON.parse(event.target.getAttribute('data-produto'));


  setAlteracaoProduto(true);
  
  setIdProdutoAtualizar(produtoData._id);

  // Definir valores do formulário usando setValue
  setValuesFormNovoEdicao('nome_produto', produtoData.nome_produto);
  setValuesFormNovoEdicao('marca', produtoData.marca);
  setValuesFormNovoEdicao('ml', produtoData.ml);
  setValuesFormNovoEdicao('qtdeParaPromocao', produtoData.qtdeParaPromocao);
  setValuesFormNovoEdicao('descricao', produtoData.descricao);
  setValuesFormNovoEdicao('precoDe', converterDecimal128ParaPrecoBrasil(produtoData.precoDe));
  setValuesFormNovoEdicao('preco', converterDecimal128ParaPrecoBrasil(produtoData.preco));
  setValuesFormNovoEdicao('categoria', produtoData.categoria);
  await atualizaSubCategorias(produtoData.categoria);

  setValuesFormNovoEdicao('sub_categoria', produtoData.sub_categoria || 'Selecione_a_Categoria');
  
  setValuesFormNovoEdicao('codigo_promocao', produtoData.codigo_promocao);
  setValuesFormNovoEdicao('validade_promocao', produtoData.dataVencimentoPromocao);
  
 
   executeScrollAtualizacao();

 
};

const categoriasDefault = getCategoriasBebidas();

    return (
       
         

        <section className="w-100 px-4 py-5 text-center bg-light">
      
      <MyVerticallyCenteredModal
      show={modalShow}
      onHide={() => setModalShow(false)}
      textBodyModal={textBodyModal}
      titleTextBodyModal={titleTextBodyModal}
      titleModal={titleModal}
    />

      <Container>
      
      {
        (textTitleIA !== null ?
          <Row>
          <div style={{ fontFamily: 'monospace', fontSize: '28px', fontWeight: 'bold', marginBottom: '12px' }}>
      <TypewriterIA text={textTitleIA} speed={100} />
    </div>
    </Row>
    :
    ''
        )
      }
      
     
      <Row className="gy-3 mb-4">
      <Col xs={12} sm={6} md={4}>
      <p>{getDataEstabelecimentoLocalStorage().endereco}</p>
    
        </Col>
        <Col xs={12} sm={6} md={4}>
        <p style={{ color: 'black', fontSize: '0.8em' }}>
      Se precisar alterar dados do seu endereço, solicite a alteração através do email: <br />
      <strong>geoget.geopromocoes@gmail.com</strong>
    </p>
        </Col>
       
        <Col xs={12} sm={6} md={4}>
        <Button variant="danger" onClick={sair} size="sm" type="submit" className="px-4">Sair</Button>
        </Col>
        </Row>
        <Tabs defaultActiveKey="tab1" id="tabsPainelEstabelecimento">
        <Tab eventKey="tab1" title="Clientes Próximos e Publicações" tabClassName="text-primary fw-bold">
          <div className="p-2">
          <h4 className="mt-3">Encontre Clientes Próximos e Gerencie Suas Publicações</h4>
          <Row>


<Col xs={12} md={6}>
<div >
                    <div className="card border-left-primary shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                    Consumidores Próximos</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{numeroClientesPorPerto}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fa fa-users fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
</Col>
<Col xs={12} md={6}>
<div >
                    <div className="card border-left-success shadow h-100 py-2">
                        <div className="card-body">
                            <div className="row no-gutters align-items-center">
                                <div className="col mr-2">
                                    <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                    Consumidores Alvo Próximos</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{numeroClientesLogados}</div>
                                </div>
                                <div className="col-auto">
                                    <i className="fa fa-users fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
</Col>
<Col xs={12} md={6}>
  
</Col>


</Row>

{

getDataEstabelecimentoLocalStorage().latitude != 'Atualizar' && 
getDataEstabelecimentoLocalStorage().longitude != 'Atualizar'

?


(<Row>
  <section key='1298' className="text-center" style={{"padding-top": "3rem", "padding-bottom": "3rem", "height": "100vh"}}>   
      <Container>             
      

      <MapLeaflet     
      arrayMarkers={leads}
      centroMapaPosicaoUser={[getDataEstabelecimentoLocalStorage().latitude,getDataEstabelecimentoLocalStorage().longitude]}
    // posicaoUser={[getDataEstabelecimentoLocalStorage().latitude,getDataEstabelecimentoLocalStorage().longitude]}
      dataUserCenter={getDataEstabelecimentoLocalStorage()}
      mapParaEstabelecimento={true}
      
      />

</Container>

</section>
  </Row>)

  :
  ''

        
        }

<Row>
	  <h4 className="mb-5" ref={scrollAreaLogadoRef}>{nomeUsuario}, Divulgue Suas Ofertas de Cervejas e Vinhos e Atraia Clientes Próximos!</h4>
   
    <Form onSubmit={onSubmitNovoEdicao(handleSubmitNovoEdicao)}>

<input type="hidden" name="tokenJwtForm" id="tokenJwtForm" value={tokenJwtForm} />

<Row>
  <Col sm={6} className="mb-3">
    <Form.Group controlId="nome_produto">
      <Form.Control
        required
        type="text"
        placeholder="Nome do Produto"
        {...register("nome_produto")}
        defaultValue=""
      />
      <Form.Text className="text-danger">
        {errors?.nome_produto?.message}
      </Form.Text>
    </Form.Group>
  </Col>
  
  <Col sm={3} className="mb-3">
    <Form.Group controlId="marca">
      <Form.Control
        required
        type="text"
        placeholder="Marca"
        {...register("marca")}
        defaultValue=""
      />
      <Form.Text className="text-danger">
        {errors?.marca?.message}
      </Form.Text>
    </Form.Group>
  </Col>
  
  <Col sm={3} className="mb-3">
    <Form.Group controlId="ml">
      <Form.Control
        required
        type="number"
        placeholder="Quantos ML"
        {...register("ml")}
        defaultValue=""
      />
      <Form.Text className="text-danger">
        {errors?.ml?.message}
      </Form.Text>
    </Form.Group>
  </Col>
</Row>

<Row>
  <Col sm={6} className="mb-3">
    <Form.Group controlId="descricao">
      <Form.Control
        required
        type="text"
        placeholder="Descrição"
        {...register("descricao")}
        defaultValue=""
      />
      <Form.Text className="text-danger">
        {errors?.descricao?.message}
      </Form.Text>
    </Form.Group>
  </Col>
  
  <Col sm={3} className="mb-3">
    <Form.Group controlId="precoDe">
      <Form.Control
        required
        type="text"
        placeholder="Preço De"
        {...register("precoDe")}
        onChange={(e) => handleChange(currencyMask(e))}
      />
      <Form.Text className="text-danger">
        {errors?.precoDe?.message}
      </Form.Text>
    </Form.Group>
  </Col>
  
  <Col sm={3} className="mb-3">
    <Form.Group controlId="preco">
      <Form.Control
        required
        type="text"
        placeholder="Preço Por"
        {...register("preco")}
        onChange={(e) => handleChange(currencyMask(e))}
      />
      <Form.Text className="text-danger">
        {errors?.preco?.message}
      </Form.Text>
    </Form.Group>
  </Col>
</Row>

<Row>
  <Col sm={3} className="mb-3">
    <Form.Group controlId="categoria">
      <Form.Control
        as="select"
        {...register('categoria')}
        isInvalid={!!errors.categoria}
        onChange={handleCategoriaUpdate}
      >
        {categoriasDefault.map(categoria => (
          <option value={categoria === 'Informe a Categoria' ? '' : categoria} key={categoria}>
            {categoria}
          </option>
        ))}
      </Form.Control>
      <Form.Text className="text-danger">
        {errors?.categoria?.message}
      </Form.Text>
    </Form.Group>
  </Col>
  
  <Col sm={3} className="mb-3">
    <Form.Group controlId="sub_categoria">
      <Form.Control
        as="select"
        {...register('sub_categoria')}
        isInvalid={!!errors.sub_categoria}
      >
        {subCategoriasEscolhidas.map(categoria => (
          <option key={categoria.codigo} value={categoria.codigo === 'Selecione_a_Categoria' ? "" : categoria.nome}>
            {categoria.nome}
          </option>
        ))}
      </Form.Control>
      <Form.Text className="text-danger">
        {errors?.sub_categoria?.message}
      </Form.Text>
    </Form.Group>
  </Col>
  
  <Col sm={3} className="mb-3">
    <Form.Group controlId="codigo_promocao">
      <Form.Label>Cód Promoção</Form.Label>
      <Form.Control
        type="text"
        {...register("codigo_promocao")}
        placeholder="Código Promoção"
        defaultValue=""
      />
    </Form.Group>
  </Col>
  
  <Col sm={3} className="mb-3">
    <Form.Group controlId="validade_promocao">
      <Form.Label>Promoção Válida Até</Form.Label>
      <Form.Control
        required
        type="date"
        {...register("validade_promocao")}
      />
    </Form.Group>
  </Col>
</Row>

<Row>
  <Col sm={3} className="mb-3">
    <Form.Group controlId="qtdeParaPromocao">
      <Form.Label>A cada Unidade?</Form.Label>
      <Form.Control
        as="select"
        {...register('qtdeParaPromocao')}
        isInvalid={!!errors.qtdeParaPromocao}
      >
        <option value=''>Qtde para esta Promoção</option>
        <option value='Por Unidade'>Por Unidade</option>
        <option value='A cada 5 unidades'>A cada 5 unidades</option>
        <option value='Por caixa ou fardo'>Por caixa ou fardo</option>
      </Form.Control>
      <Form.Text className="text-danger">
        {errors?.qtdeParaPromocao?.message}
      </Form.Text>
    </Form.Group>
  </Col>
</Row>

<Button variant="primary" type="submit" className="px-4 float-right">
  {alteracaoProduto ? 'Atualizar' : 'Publicar'}
</Button>
</Form>

	  </Row>
    <hr/>

    <Row className="mt-4">
      <h4 className="mb-5">{nomeUsuario}, {areaProdutos.length > 0 && null != areaProdutos 
      ?
    'Atualize ou Remova seus produtos:'
      :
      'Mostre seus produtos para clientes ao seu redor'
    
    }</h4>
      </Row>
      <Row className="mt-4 text-center">
      {areaProdutos.length > 0 && null != areaProdutos 
      ? 
      //Logica para atualizar cards de produtos
         
          areaProdutos

       
      
      : 
      //Não tem produtos Cadastrados
      <p>'Publique seus Produtos'</p>
      }
	</Row>

          </div>
        </Tab>
        <Tab eventKey="tab2" title="Estatísticas e Insights" tabClassName="text-primary fw-bold">
          <div className="p-3">
          {(estatisticasEstabelecimento !== null ? <SectionEstatisticasEstabelecimento 
        qtdeLeadsPeriodoRaio={estatisticasEstabelecimento} 
        estatisticaValorMedioProdutosRegiao={estatisticaValorMedioProdutosRegiao}
        estatisticaCliquesToMapPordutos = {estatisticaCliquesToMapPordutos}
        /> : '' )}
		
		
		
          </div>
        </Tab>
        <Tab eventKey="tab3" title="Inteligência Artificial GeoGet" tabClassName="text-primary fw-bold">
          <div className="p-3">
          <h3 className="mt-3">Chat com Inteligência Geoget</h3>
          <p>Converse com a Inteligência Artificial da Geoget para obter ajuda, informações e dicas para seu Estabelecimento.</p>
          { 
     ( 
      getDataEstabelecimentoLocalStorage().isPlanoAtivo ? 
      <SectionQuestionarioComChatIAEstabelecimentos />
      : 
      <SectionPrecoPlanoEstabelecimentos />
      )  
      }
          </div>
        </Tab>
        <Tab eventKey="tab4" title="Controle de Indicações de Motoristas" tabClassName="text-primary fw-bold">
          <div className="p-3">

          <Form.Group controlId="participacaoRedeIndicacoes">
      <Form.Check
        type="checkbox"
        label="Quero participar da rede de Indicações de Motoristas, meu Estabelecimento terá suas promoções indicadas aos passageiros por motoristas que estiverem passando pela região"
        onChange={(e) => handleCheckboxChange(e.target.checked)}
        checked={isCheckedIsEstabelecimentoParceiroMotoristas}
        className="d-flex align-items-center"
      />
    </Form.Group>


          <h4 className="mt-3">Gerencie as Indicações de Motoristas para o Seu Estabelecimento</h4>
          <Container className="mt-4">
      <Row>
        {/* Coluna para indicar a comissão do motorista */}
        <Col md={6}>
          <h5>Comissão por Indicação de Cliente</h5>
          <p>Informe os detalhes da comissão que o motorista vai ganhar por ter indicado e levado o cliente ao estabelecimento.</p>
          <Form onSubmit={onSubmitComissaoMotorista(handleSubmitComissaoMotoristaForm)}>
            <Form.Group controlId="confirmacaoCorrida">
            <Alert variant="warning">
    Atenção: Antes de confirmar, verifique com o cliente se ele realmente chegou através da corrida com o motorista.
  </Alert>
  <Form.Check 
    type="checkbox" 
    label="Confirmo que o cliente chegou através de uma corrida com o Motorista." 
    required  
  />
            </Form.Group>
            
            <Form.Group controlId="codigoMotoristaComissao" className="mt-3">
              <Form.Label>Código do Motorista</Form.Label>
              <Form.Control 
              type="text" 
              placeholder="Digite o código do motorista"
              id="codigoMotoristaComissao" 
              {...registerComissaoMotorista("codigoMotoristaComissao")} 
                />
               <span style={{'color':'red'}}>{errorsComissaoMotrista?.codigoMotoristaComissao?.message}</span>
            </Form.Group>
            
            <Form.Group controlId="valorContaClienteControl" className="mt-3">
              <Form.Label>Valor Total da Conta do Cliente</Form.Label>
              
              <Form.Control
            required
            type="text"
            id="valorContaCliente" 
            {...registerComissaoMotorista("valorContaCliente")}
            placeholder="0,00"
            defaultValue=""
            onChange={(e) => handleChange(currencyMask(e))} 
          />
              
              
               <span style={{'color':'red'}}>{errorsComissaoMotrista?.valorContaCliente?.message}</span>
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">Enviar</Button>
          </Form>
        </Col>

        {/* Linha de separação */}
        <Col md={1} className="d-flex justify-content-center align-items-center">
          <hr style={{ height: '100%', border: '1px solid #ddd', margin: 0 }} />
        </Col>

        {/* Coluna para utilização de créditos do motorista */}
        <Col md={5}>
          <h5>Utilização de Créditos do Motorista</h5>
          <p>Utilize este campo para descontar os créditos acumulados pelo motorista, quando o Motorista consumir no estabelecimento.</p>
          <Form onSubmit={onSubmitFormDescontoCreditoMotorista(handleSubmitComsumacaoMotorista)}>

          <Form.Group controlId="codigoMotoristaConsumacao" className="mt-3">
              <Form.Label>Código do Motorista</Form.Label>
              <Form.Control 
              type="text" 
              placeholder="Digite o código do motorista" 
              required
              id="codigoMotristaDesconto"
              {...registerDescontoCreditoMotorista("codigoMotristaDesconto")}
              />
            </Form.Group>
            
            <Form.Group controlId="valorTotalContaMotorista">
              <Form.Label>Valor Total da Conta do Motorista</Form.Label>
              <Form.Control
            required
            type="text"
            id="valorTotalContaMotorista" 
            {...registerDescontoCreditoMotorista("valorTotalContaMotorista")}
            placeholder="0,00"
            defaultValue=""
            onChange={(e) => handleChange(currencyMask(e))} 
          />
            </Form.Group>
            
            <Form.Group controlId="valorUtilizarMotorista" className="mt-3">
              <Form.Label>Valor a Utilizar do Motorista</Form.Label>
              <Form.Control
            required
            type="text"
            id="valorUtilizarMotorista" 
            {...registerDescontoCreditoMotorista("valorUtilizarMotorista")}
            placeholder="0,00"
            defaultValue=""
            onChange={(e) => handleChange(currencyMask(e))} 
          />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-3">Enviar</Button>
          </Form>
        </Col>
      </Row>
    </Container>
          </div>
        </Tab>
      </Tabs>
        
       
        
        
       
      
	  
      
  { /*<hr/>
  <Row className="mt-4 text-center">
  <h4 className="mb-5">Te deixamos poderoso com esse QRCdoe. Imprima e coloque no seu Comércio para que seus clientes utilizem para realizar o Marketing de seus produtos cadastrados.</h4>
  <Col  className="mb-3"></Col>
  
  <Col  className="mb-3">
                         
                        {
                        qrCode !== '' ?  
                        <img ref={imgQrcode} src={qrCode} style={{width: 320, height: 320}} />
                          
                        : 
                        'Estamos Gerando seu QRcode, entre em contato com a Administração'}
                        
                    </Col>
                    <Col  className="mb-3"></Col>
    </Row>
    <Row className="mt-4 text-center">

    
            <Col  className="mb-3"></Col>
  
  <Col  className="mb-3">
                         
  <h3>Pegue aqui seu QRCode e deixe exposto em seu Comércio</h3>
                 
                <Button onClick={(e) => downloadQrCode(e)}>Faça Download do seu QRCode</Button>
                        
                    </Col>
                    <Col  className="mb-3"></Col>

    </Row>*/}
</Container>   
	 
	    {/* Modal para editar motorista */}
      <Modal show={showModalConfirmComissaoMotorista} onHide={() => setShowModalConfirmComissaoMotorista(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirme os Dados:</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
            <Form.Group controlId="formNomeMoto">
                <Form.Label>Nome Motorista: {(nomeMotoristaConsultado ? nomeMotoristaConsultado : '')}</Form.Label>
               
              </Form.Group>
            
            <Form.Group controlId="formCodMoto">
                <Form.Label>Codigo Motorista: {(codigoMotoristaConsultado ? codigoMotoristaConsultado : '')}</Form.Label>
               
              </Form.Group>
              <Form.Group controlId="formApp">
                <Form.Label>App: {(appMotoristaConsultado ? appMotoristaConsultado : '')}</Form.Label>
               
              </Form.Group>
              <Form.Group controlId="formValConta">
                <Form.Label>Valor Conta: {(valorContaPosConsulta ? valorContaPosConsulta : '')}</Form.Label>
                
              </Form.Group>
              <Button variant="primary" onClick={() => handleSubmitComissaoMotoristaModal()}>
                Confirmo 
              </Button>
              <Button variant="secondary" onClick={() => setShowModalConfirmComissaoMotorista(false)} style={{ marginLeft: '10px' }}>
            Cancelar
          </Button>
            
          </Modal.Body>
        </Modal>
     
    </section>



    );
}

export default SectionEdicaoProdutos;





