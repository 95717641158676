import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';




const SectionTermosUso = () => {
    return (
        <section>
       <Container>
     <Row>
     <Col>
     <div style={{ margin: '20px', padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: 1.6 }}>
      <h1>Termos de Uso</h1>

      <p>Bem-vindo ao nosso site. Ao continuar a navegar e utilizar este site, você está concordando em cumprir e ser regido pelos seguintes termos e condições de uso, que juntamente com a nossa política de privacidade regem o relacionamento da GeoGet com você em relação a este site. Se você discordar de qualquer parte destes termos e condições, por favor, não use o nosso site.</p>

      <h2>1. Termos</h2>
      <p>Ao acessar o site da GeoGet, você concorda em cumprir estes termos de serviço, todas as leis e regulamentos aplicáveis, e concorda que é responsável pelo cumprimento de todas as leis locais aplicáveis. Se você não concordar com algum desses termos, está proibido de usar ou acessar este site. Os materiais contidos neste site são protegidos pelas leis de direitos autorais e marcas comerciais aplicáveis.</p>

      <h2>2. Licença de Uso</h2>
      <p>É concedida permissão para utilização das informações visualizadas no site da GeoGet. Vocênão pode:</p>
      <ul>
        <li>Modificar ou copiar os materiais;</li>
        <li>Usar os materiais e informações para qualquer finalidade comercial ou para exibição pública (comercial ou não comercial);</li>
        <li>Tentar descompilar ou fazer engenharia reversa de qualquer software contido no site da GeoGet;</li>
        <li>Remover quaisquer direitos autorais ou outras notações de propriedade dos materiais;</li>
        <li>Transferir os materiais para outra pessoa ou "espelhar" os materiais e informações em qualquer outro servidor.</li>
      </ul>
      <p>Esta licença será automaticamente rescindida se você violar qualquer dessas restrições e pode ser rescindida pela GeoGet a qualquer momento. Ao encerrar a visualização desses materiais e informações ou após o término desta licença, você deve apagar todos os materiais baixados em sua posse, seja em formato eletrónico ou impresso.</p>

      <h2>3. Isenção de Responsabilidade</h2>
      <p>Os materiais e informações no site da GeoGet são fornecidos "como estão". GeoGet não oferece garantias, expressas ou implícitas, e, por este meio, isenta e nega todas as outras garantias, incluindo, sem limitação, garantias implícitas ou condições de comercialização, adequação a um propósito específico ou não violação de propriedade intelectual ou outra violação de direitos. Além disso, a GeoGet não garante ou faz qualquer representação relativa à precisão, aos resultados prováveis ou à confiabilidade do uso dos materiais e informações em seu site na Internet ou de outra forma relacionado a tais materiais ou em sites vinculados a este site.
      O uso do bate-papo com a Inteligência Artificial GeoGet não garante que as informações fornecidas sejam precisas ou infalíveis. A GeoGet se exime de responsabilidade por decisões tomadas com base nas respostas da IA.
      </p>

      <h2>4. Limitações</h2>
      <p>Em nenhum caso a GeoGet ou seus fornecedores serão responsáveis por quaisquer danos (incluindo, sem limitação, danos por perda de dados ou lucro, ou devido à interrupção de negócios,) resultantes do uso ou da incapacidade de usar os materiais, informações e disponibilização de informações no site da GeoGet, mesmo que GeoGet ou um representante autorizado da GeoGet tenha sido notificado oralmente ou por escrito da possibilidade de tais danos. Como algumas jurisdições não permitem limitações em garantias implícitas, ou limitações de responsabilidade por danos consequentes ou incidentais, essas limitações podem não se aplicar a você.</p>

      <h2>5. Cobranças e Funcionalidades Pagas</h2>
      <p>A GeoGet reserva-se o direito de cobrar por determinadas funcionalidades e serviços oferecidos na plataforma. Essas cobranças podem incluir, mas não se limitam a, acesso a funcionalidades premium, serviços de localização avançados e outros serviços adicionais.
        O dono do estabelecimento poderá optar por pagar um plano que lhe concede o direito de acessar funcionalidades restritas e exclusivas da plataforma GeoGet.</p>

      <h2>6. Comissões de Motoristas e Taxistas</h2>
      <p>Os motoristas de aplicativos de transporte urbano ou taxistas que utilizam a plataforma GeoGet concordam com a taxa de comissão estipulada pela GeoGet para os serviços de intermediação. A utilização da plataforma por parte dos motoristas implica a aceitação prévia dessas condições.</p>

      <h2>7. Revisões e Errata</h2>
      <p>Os materiais exibidos no site da GeoGet podem incluir erros técnicos, tipográficos ou fotográficos. GeoGet não garante que qualquer material em seu site seja preciso, completo ou atual. GeoGet pode fazer alterações nos materiais contidos em seu site a qualquer momento, sem aviso prévio. GeoGet, no entanto, não se compromete a atualizar os materiais.</p>

      <h2>8. Links</h2>
      <p>GeoGet não analisou todos os sites vinculados ao seu site na Internet e não é responsável pelo conteúdo de qualquer site vinculado. A inclusão de qualquer link não implica endosso por GeoGet do site. O uso de qualquer site vinculado é por conta e risco do usuário.</p>

      <h2>9. Modificações dos Termos de Uso do Site</h2>
      <p>GeoGet pode revisar estes termos de uso de seu site a qualquer momento, sem aviso prévio. Ao usar este site, você concorda em ficar vinculado pela versão atual desses Termos e Condições de Uso.</p>

      <h2>10. Lei Aplicável</h2>
      <p>Qualquer reclamação relativa ao site da GeoGet será regida pelas leis do Estado de São Paulo, sem consideração às suas disposições sobre conflitos de leis.</p>

      <p>Termos e Condições Gerais aplicáveis ao Uso de um Site.</p>
    </div>
    </Col>
      </Row>
      </Container>
      </section>
    );
}

export default SectionTermosUso;