import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';




const SectionSobreGeoGet = () => {
    return (
        <Container>
     <Row>
     <Col>
        <div style={{ margin: '20px', padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: 1.6 }}>
      <h1>Sobre a GeoGet</h1>

      <h2>Transformando a Geolocalização em Oportunidades</h2>
      <p>
        Na GeoGet, estamos revolucionando a forma como você descobre promoções e ofertas em sua região. Utilizando tecnologia de ponta em geolocalização, conectamos você com os melhores estabelecimentos ao seu redor, proporcionando uma experiência única e personalizada.
      </p>

      <h2>Nossa Missão</h2>
      <p>
        Nossa missão é simples: facilitar a sua busca por ofertas incríveis e trazer à sua porta as melhores oportunidades de compra. Queremos que cada usuário tenha acesso rápido e fácil a promoções exclusivas de bebidas, para que você possa aproveitar ao máximo os melhores preços e variedades sem complicação.
      </p>

      <h2>Como Funciona</h2>
      <p>
        Imagine um mundo onde você pode localizar as melhores promoções de cervejas e vinhos em questão de segundos. A GeoGet utiliza um sistema avançado de rastreamento em tempo real para informar você sobre as melhores ofertas próximas, atualizando constantemente as informações conforme você se desloca. Com a nossa plataforma, encontrar a bebida perfeita pelo preço ideal nunca foi tão fácil.
      </p>

      <h2>Por que Escolher a GeoGet?</h2>
      <ul>
        <li><strong>Tecnologia de Ponta:</strong> Nosso sistema de geolocalização é preciso e confiável, garantindo que você sempre encontre as melhores ofertas perto de você.</li>
        <li><strong>Atualizações em Tempo Real:</strong> Receba notificações instantâneas sobre promoções e ofertas enquanto você se move, garantindo que você nunca perca uma oportunidade.</li>
        <li><strong>Parcerias Exclusivas:</strong> Trabalhamos com os melhores estabelecimentos para trazer ofertas exclusivas que você não encontrará em nenhum outro lugar.</li>
        <li><strong>Experiência Personalizada:</strong> Personalize suas preferências e receba recomendações que atendam exatamente ao que você procura.</li>
      </ul>

      <h2>Junte-se a Nós</h2>
      <p>
        Na GeoGet, acreditamos que a inovação começa com a simplicidade. Nossa plataforma foi criada para ser intuitiva e fácil de usar, garantindo que todos possam aproveitar ao máximo as vantagens da geolocalização. Junte-se à nossa comunidade e descubra um mundo de ofertas e promoções que estão apenas esperando para serem descobertas.
      </p>
    </div>
    </Col>
      </Row>
      </Container>
    );
}

export default SectionSobreGeoGet;