import Container from "react-bootstrap/Container";
import { MapContainer, TileLayer, Marker, Popup, Circle } from "react-leaflet";
import { converterDecimal128ParaPrecoBrasil } from "../util/util";
import L from 'leaflet';
import React from "react";

 
delete L.Icon.Default.prototype._getIconUrl;

const iconPersonLocation = new L.Icon({
  iconUrl: require('../../src/assets/images/leaflet/icon-person.png'),
  //iconRetinaUrl: require('../../src/assets/images/leaflet/icon-person-2x.png'),
  iconSize: [64, 64],
    iconAnchor:[32, 64], // point of the icon which will correspond to marker's location
 
    popupAnchor:[0, -57] // point from which the popup should open relative to the iconAnchor
});

const iconStoreLocation = new L.Icon({
  iconUrl: require('../../src/assets/images/leaflet/icon-store-non-bg-64-64.png'),
  
  iconSize: [64, 64],
    iconAnchor:   [17, 46], // point of the icon which will correspond to marker's location
 
    popupAnchor:  [15, -35] // point from which the popup should open relative to the iconAnchor
});

const iconMapMarkerWine = new L.Icon({

  iconUrl: require('../../src/assets/images/leaflet/map_marker_wine_48_48.png'),
  iconSize: [48,48],
  iconAnchor:   [16, 37], // point of the icon which will correspond to marker's location
  popupAnchor:  [0, -30] // point from which the popup should open relative to the iconAnchor

});

const iconMapMarkerDrinks = new L.Icon({

  iconUrl: require('../../src/assets/images/leaflet/map_marker-wine-and-glass_48_48.png'),
  iconSize: [32,37],
  iconAnchor:   [16, 37], // point of the icon which will correspond to marker's location
  popupAnchor:  [0, -30] // point from which the popup should open relative to the iconAnchor

});

const iconMapMarkerBeer = new L.Icon({

  iconUrl: require('../../src/assets/images/leaflet/map_marker_beer_48_48.png'),
  iconSize: [32,37],
  iconAnchor:   [16, 37], // point of the icon which will correspond to marker's location
  popupAnchor:  [0, -30] // point from which the popup should open relative to the iconAnchor

});

const iconMapMarkerPerson = new L.Icon({

  iconUrl: require('../../src/assets/images/leaflet/map_marker_person.png'),
  iconSize: [32,37],
  iconAnchor:   [16, 37], // point of the icon which will correspond to marker's location
  popupAnchor:  [0, -30] // point from which the popup should open relative to the iconAnchor

});

const iconMapMarkerCar = new L.Icon({

  iconUrl: require('../../src/assets/images/leaflet/map_marker_car.png'),
  iconSize: [32,37],
  iconAnchor:   [16, 37], // point of the icon which will correspond to marker's location
  popupAnchor:  [0, -30] // point from which the popup should open relative to the iconAnchor

});


const MapLeafletForUsers = (props) => {

   
  
      function getMarkersIcon(categorias,establecimentoUsuario) {
       
        
        if(establecimentoUsuario.nome_estabelecimento == null){
            //Usuario
            return iconMapMarkerCar;
           }
           else{
            //Estabelecimento

            if(categorias.indexOf('Vinhos') >= 0 && categorias.indexOf('Cervejas') >= 0) {
              return iconMapMarkerDrinks;
            }
            else if (categorias.indexOf('Vinhos') >= 0){
            return iconMapMarkerWine;
            }else if(categorias.indexOf('Cervejas') >= 0){
            return iconMapMarkerBeer;
           }else if(categorias.indexOf('Carros') >= 0){
             return iconMapMarkerCar;
            }

           }

              
            }

            function getListaProdutos(produtos){
             

              const result = produtos.flatMap((prod) => prod.nome_produto+' '+prod.ml+'ml R$'+converterDecimal128ParaPrecoBrasil(prod.preco) + '\n' );

              return  <div style={{'white-space': 'pre'}}>{result}</div>;

            }
  
            function getMarkerIcon() {
                return iconPersonLocation; 
            
                  }

          

            function getLinkMapGoogle(estabelecimentoCliente){
              let urlGooGleMaps = new URL('https://www.google.com/maps/dir/');
  
            urlGooGleMaps.searchParams.append('q', estabelecimentoCliente.rua+','+estabelecimentoCliente.numero+' - '+estabelecimentoCliente.bairro+','+' '+estabelecimentoCliente.cidade+' - '+estabelecimentoCliente.estado+', '+estabelecimentoCliente.cep);
          
            return  <a href={urlGooGleMaps} target="_blank">Ir Até la</a>;
            }
  
                  
       return (
          <>
         
          
          { (
             props.centroMapaPosicaoUser !== null &&
             props.centroMapaPosicaoUser  !== ''   
  
              ?
              
              <>
  
              <section key='222333412' className="text-center" style={{"padding-top": "3rem", "padding-bottom": "3rem", "height": "100vh"}}>   
              <Container>             
              
              <h4 className="mb-5"> Descubra os melhores lugares para saborear cervejas e vinhos perto de você, direto no mapa! </h4>
              <MapContainer style={{ height: "80vh" }} center={props.centroMapaPosicaoUser} zoom={14} scrollWheelZoom={false}>
  <TileLayer
  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
  />
   
  
  {
    (
      // MARKER DO USER NO CENTRO
        <Marker 
        position={props.centroMapaPosicaoUser} 
      icon={ getMarkerIcon() } >      
           <Popup>
         {props.dataUserCenter.nome}  <br /> 
    </Popup>
    <Circle 
                  center={{lat:props.centroMapaPosicaoUser[0], lng: props.centroMapaPosicaoUser[1]}}
                  fillColor="Blue" 
                  radius={Number(props.raioCirculo)}/>
        </Marker>
    )
  }
  
   
          {
          
          (
            // MARKERS DO USER DOS ESTABELECIMENTOS PROXIMOS
            props.arrayMarkers != null && props.arrayMarkers.length > 0 ?
              //arrayMarkers = estabelecimentos
            props.arrayMarkers.map((maker, index) => { //maker =estabelecimento
              const arrayProdutos = maker.produtos_venda;
              var isEstabelecimento = (maker.nome_estabelecimento != null && maker.nome_estabelecimento != 'undefined'  ?   true : false   );
              return <Marker 
              key={index} 
              position={{lat:maker.latitude,lng:maker.longitude}}
              icon={getMarkersIcon(maker.categorias,maker)} 
  
              >
                 <Popup>
               
                
                  
               {
              
              (isEstabelecimento ? maker.tipo_estabelecimento+' '+maker.nome_estabelecimento  : 'Particular')
             
              } 
          <br />
          {(arrayProdutos !== null && arrayProdutos.length> 0 ?  getListaProdutos(arrayProdutos) : '')}
          <br />
            {
              (isEstabelecimento ? getLinkMapGoogle(maker)  : '')
            }
           <br />
                 
              {
              (!isEstabelecimento ? 
              arrayProdutos[0].nome_produto
              :
               ''  )
             
              }
               <br />
               {
                (!isEstabelecimento ? 
                 'Ano:'+arrayProdutos[0].ano_modelo
                  :
                   ''  )
               }
               <br />
               {
                (!isEstabelecimento ? 
                 'Valor:'+ arrayProdutos[0].preco
                  :
                   ''  )
               }
              
          </Popup>
              </Marker>;
            })
  
            :
  ''
          )
          
         }
  
  </MapContainer>
  
  </Container>
  
  </section>
  
  
  
  
            </>
              :
  
              ''
          )  
                     
                  }
          
         
          </>
       );
  

}

export default MapLeafletForUsers;